import { ApolloClient, ApolloLink, concat, InMemoryCache } from '@apollo/client';

import { authLink } from './graphql/links/auth.link';
import { httpLink } from './graphql/links/httpLink';
import { errorLink } from './graphql/links/error.link';

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  // link: ApolloLink.from([concat(authLink, httpLink), errorLink])
  link: ApolloLink.from([authLink, errorLink, httpLink])
});

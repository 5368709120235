import { Pane, PaneOwnProps, BoxOwnProps } from 'evergreen-ui';

interface IProps {
  label: string;
  element: JSX.Element | string | null | undefined;
  placeholder: string;
  iconAfter?: JSX.Element;
}

const ModalInfoRow = ({
  label,
  element,
  placeholder,
  iconAfter,
  ...props
}: IProps & PaneOwnProps & BoxOwnProps) => {
  return (
    <Pane
      width="100%"
      marginTop={16}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      {...props}
    >
      <Pane className="green-label" position="absolute" top={0}>
        {label}
      </Pane>
      {element ? (
        <Pane marginTop={14}>{element}</Pane>
      ) : (
        <Pane className="info-text" color="rgba(111, 118, 126, 0.5)">
          {placeholder}
        </Pane>
      )}
      {iconAfter ? iconAfter : null}
    </Pane>
  );
};

export default ModalInfoRow;

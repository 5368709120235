import { forwardRef } from 'react';
import { TextInputField, BoxOwnProps, Pane } from 'evergreen-ui';
import GreenCalendarIcon from '../../Icons/GreenCalendarIcon';

interface IProps extends BoxOwnProps {
  id?: string;
  styles?: any;
  label?: string;
  isSecure?: boolean;
  className?: string;
  value: Date | null | string;
  placeholder?: string;
  iconBackgroundColor?: string;
  greenLabel?: string;
  onClick?: (() => void) | undefined;
  onChange: (value: Date | null) => void;
}

const GreenCalendarInput = ({
  id,
  label,
  value,
  isSecure,
  placeholder,
  className = '',
  iconBackgroundColor = '#F4F4F4',
  onClick,
  onChange,
  greenLabel,
  ...props
}: IProps) => {
  if (greenLabel && value) {
    return (
      <Pane
        position="relative"
        className="calendar-input"
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop={32}
        width="100%"
        backgroundColor="#FFFFFF !important"
        borderRadius={12}
        overflow="hidden"
        height={48}
      >
        <Pane position="absolute" top={6} left={12} className="green-label">
          {greenLabel.toUpperCase()}
        </Pane>
        <TextInputField
          width="100%"
          className={`input-box input-box__green-label input-box_green input-box__with-icon ${className}`}
          placeholder={placeholder || ''}
          value={value}
          label=""
          onChange={(e: any) => onChange(e.target.value)}
          id={id}
          margin={0}
          padding={0}
          {...props}
          {...(onClick ? { onClick } : {})}
          {...props}
        />
        <Pane
          backgroundColor={`${iconBackgroundColor} !important`}
          width={48}
          height={48}
          marginRight={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <GreenCalendarIcon />
        </Pane>
      </Pane>
    );
  }

  return (
    <Pane
      className="calendar-input"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={32}
      width="100%"
      backgroundColor="#FFFFFF !important"
      borderRadius={12}
      overflow="hidden"
      height={48}
    >
      <TextInputField
        width="100%"
        className={`input-box input-box_green input-box__with-icon ${className}`}
        placeholder={placeholder || ''}
        value={value}
        label=""
        onChange={(e: any) => onChange(e.target.value)}
        id={id}
        onClick={onClick}
        margin={0}
        padding={0}
        {...props}
      />
      <Pane
        backgroundColor={`${iconBackgroundColor} !important`}
        width={48}
        height={48}
        marginRight={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <GreenCalendarIcon />
      </Pane>
    </Pane>
  );
};

export default forwardRef(GreenCalendarInput);

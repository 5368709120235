import { Button, BoxOwnProps } from 'evergreen-ui';
import './index.scss';

interface IProps extends BoxOwnProps {
  title: string;
  onClick?: () => void;
  iconBefore?: false | JSX.Element | React.ElementType<any> | null | undefined;
  disabled?: boolean;
}

const GreenButton = ({
  title,
  onClick,
  iconBefore = null,
  disabled = false,
  ...rest
}: IProps) => {
  return (
    <Button
      className="button-font-style green-button"
      color="#FCFCFC !important"
      background="#096B72"
      marginY={8}
      height="48px !important"
      border="2px solid #096B72"
      borderRadius={8}
      onClick={onClick}
      iconBefore={iconBefore}
      isActive={disabled}
      {...rest}
    >
      {title}
    </Button>
  );
};

export default GreenButton;

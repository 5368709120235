import { ChangeEvent, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  FilterFn,
} from '@tanstack/react-table';
// import { useNavigate } from 'react-router-dom';
import { PlusIcon, SearchIcon } from 'evergreen-ui';
import TitleSmall from '../../../components/TitleSmall';
import AddClinicModal from '../../../components/Modals/AddClinicModal';
import SuccessModal from '../../../components/Modals/SuccessModal';
import { IClinic } from '../../../interfaces/IClinic';
import ClinicsTable from './ClinicsTable';
import { colors } from '../../../colors';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CLINICS } from '../../../graphql/queries/clinics.query';
import { MobileBadge } from '../../../components/MobileBadge';
import { EmailButton } from '../../../components/EmailButton';
import { CREATE_CLINIC } from '../../../graphql/mutations/create-clinic';

// enum DoctorStatus {
//   REGISTERED = 'registered',
//   INVITED = 'invited',
//

const globalFilterFn: FilterFn<IClinic> = (row, columnId, filterValue) => {
    // if (columnId === 'tag') {
    //   const tags = row.original.tags ?? [];
    //   const tag = tags.find((x: string) =>
    //     x.toLowerCase().includes(filterValue.toLowerCase())
    //   );
    //   return tag ? true : false;
    // }
  
    const data: string = row.getValue(columnId);
    return data.toLowerCase().includes(filterValue.toLowerCase());
  };

const Clinics = () => {
    const toast = useToast();
//   const navigate = useNavigate();

  const [status, setStatus] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [isAddClinic, setIsAddClinic] = useState(false);

  const clinicsQuery = useQuery(GET_CLINICS);

  const [createClinic, { loading }] = useMutation(CREATE_CLINIC, {
    refetchQueries: [{ query: GET_CLINICS }],
  });

  // const handleStatusChanged = (value: DoctorStatus) => {
  //   setStatus(value);
  // };

  const handleSaveClinics = async (data: any) => {
    try {
      await createClinic({
        variables: {
          input: {
            name: data.name,
            surname: data.surname,
            email: data.email,
            specialization: data.specialization,
          }
        },
      });
      setIsAddClinic(false);
    } catch (error: any) {
      toast({
        title: 'Errore durante la creazione del dottore.',
        description: error.message,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const columnsDef: ColumnDef<IClinic>[] = [
    {
      id: 'name',
      header: 'Nome',
      accessorFn: (row) => `${row.name} ${row.surname}`,
      cell: ({ row, getValue }) => {
        const id = row.original.id;
        const name = getValue() as string;

        const handleClick = () => {
        //   navigate(`/doctors/${id}`);
        };

        return (
          <Flex alignItems="center" gap="10px">
            <Avatar name={name} />
            <Button
              variant="link"
              colorScheme="teal"
              fontSize="15px"
              fontWeight="bold"
              color="brand.700"
              onClick={handleClick}
              whiteSpace="break-spaces"
              textAlign="start"
            >
              {name}
            </Button>
          </Flex>
        );
      },
    },
    {
      header: 'Contatto',
      accessorFn: (row) => ({ email: row.email, mobile: row.phone }),
      id: 'contact',
      cell: ({ getValue }) => {
        const values = getValue() as {
          email: string | undefined;
          phone: string | undefined;
        };

        return (
          <Flex gap="10px" alignItems="center">
            {values.phone && <MobileBadge mobile={values.phone} />}
            {values.email && <EmailButton email={values.email} />}
          </Flex>
        );
      },
    },
    {
      header: 'Tag',
      accessorKey: '',
    },
  ];

  const data = useMemo(
    () => clinicsQuery.data?.clinics || [],
    [clinicsQuery.data]
  );
  const columns = useMemo(() => columnsDef, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    globalFilterFn: globalFilterFn,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (clinicsQuery.loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }
    return (
        <Box h="100%" mr="91px" ml="91px">
          <AddClinicModal
            isOpen={isAddClinic}
            onClose={() => setIsAddClinic(false)}
            loading={loading}
            onSave={handleSaveClinics}
          />
          <SuccessModal isOpen={isSuccess} setIsOpen={() => setIsSuccess(false)} />
    
          <Text fontSize="40px" fontWeight="semibold" pt="40px" pb="24px">
            Lista cliniche
          </Text>
    
          <Box backgroundColor="brand.300">
            <Flex
              height="76px"
              paddingInlineStart="24px"
              paddingInlineEnd="24px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex alignItems="center">
                <TitleSmall
                  text="Cliniche"
                  color={colors[100]}
                  style={{ marginRight: 24 }}
                />
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray" />}
                  />
                  <Input width="360px"
                    placeholder="Cerca per nome o tag"
                    // value={globalFilter ?? ''}
                    background="brand.400 !important"
                    // onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    //   setGlobalFilter(e.target.value)
                    // }
                  />
                </InputGroup>
              </Flex>
    
              {/* <Box>
                <Button
                  colorScheme="gray"
                  variant="ghost"
                  isActive={status === DoctorStatus.REGISTERED}
                  onClick={() => handleStatusChanged(DoctorStatus.REGISTERED)}
                  marginRight={2}
                >
                  Registrato
                </Button>
                <Button
                  colorScheme="gray"
                  variant="ghost"
                  isActive={status === DoctorStatus.INVITED}
                  onClick={() => handleStatusChanged(DoctorStatus.INVITED)}
                >
                  Invitato
                </Button>
              </Box> */}
    
              <Button
                colorScheme="teal"
                variant="solid"
                onClick={() => setIsAddClinic(true)}
                leftIcon={<PlusIcon />}
                width="188px"
                height="48px"
                backgroundColor="brand.500"
              >
                Aggiungi clinica
              </Button>
            </Flex>
          </Box>
    
          <ClinicsTable table={table} />
        </Box>
      );
};



export default Clinics;
import { useState } from 'react';
import * as yup from 'yup';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TitleSmall from '../../TitleSmall';
import { IModal } from '../../../interfaces/IModal';
import { colors } from '../../../colors';
import './index.scss';

const schema = yup.object({
  name: yup.string().required('Inserisci il tuo nome'),
  surname: yup.string().required('Inserisci il tuo nome'),
  amount: yup.string().required('Inserisci il numero di medici'),
  adress: yup.string().required("Inserisci l'indirizzo"),
  email: yup.string().email(`L'indirizzo email inserito non è valido`),
});

const AddClinicModal = ({ isOpen, loading, onClose, onSave }: IModal) => {
  const [tags, setTags] = useState<string[]>([]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    onSave(data);
  };

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      isCentered={true}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent backgroundColor="brand.600">
        <ModalHeader pt="24px">
          <TitleSmall text="Nuova clinica" color={colors[200]} />
        </ModalHeader>
        <ModalCloseButton mt="8px" mr="8px" borderRadius="23px" />

        <ModalBody pb={6} mt="16px">
          <form id="clinic-form" onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing="32px" alignItems="unset">
            <FormControl isInvalid={!!errors.name}>
                <FormLabel htmlFor="name" fontSize="14px">
                  Nome ambulatrio o centro medico*
                </FormLabel>
                <Input
                  id="name"
                  placeholder="Nome"
                  {...register('name')}
                  variant="solid"
                />
                <FormErrorMessage>
                  {errors.name?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.amount}>
                <FormLabel htmlFor="amount" fontSize="14px">
                  Numero medici presenti*
                </FormLabel>
                <Input
                  id="amount"
                  placeholder="Inserire una quantità"
                  {...register('amount')}
                  variant="solid"
                />
                <FormErrorMessage>
                  {errors.amount?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.adress}>
                <FormLabel htmlFor="adress" fontSize="14px">
                  Indirizzo*
                </FormLabel>
                <Input
                  id="adress"
                  placeholder="Inserisci indirizzo"
                  {...register('adress')}
                  variant="solid"
                />
                <FormErrorMessage>
                  {errors.adress?.message}
                </FormErrorMessage>
              </FormControl>
              {/* <GreenTagInput
                label="Servizi associati"
                values={tags}
                onChange={(values) => setTags(values)}
              /> */}
              <Flex justifyContent="space-between" alignItems="flex-end">
                <FormControl mr="16px" isInvalid={!!errors.name}>
                  <FormLabel htmlFor="name" fontSize="14px">
                    Nome completo del referente*
                  </FormLabel>
                  <Input
                    id="name"
                    placeholder="Nome"
                    {...register('name')}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.surname}>
                  <Input
                    id="surname"
                    placeholder="Cognome"
                    {...register('surname')}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.surname?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex justifyContent="space-between" alignItems="flex-end">
                <FormControl mr="16px" isInvalid={!!errors.email}>
                  <FormLabel htmlFor="email" fontSize="14px">
                    Informazioni di contatto del referente*
                  </FormLabel>
                  <Input
                    id="email"
                    placeholder="Email del referente"
                    {...register('email')}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.email}>
                  <Input
                    id="email"
                    placeholder="PEC"
                    {...register('email')}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            </VStack>
          </form>
        </ModalBody>

        <ModalFooter pb="24px" justifyContent="space-between">
          <Button
            colorScheme="gray"
            variant="outline"
            onClick={onClose}
            backgroundColor="white"
            disabled={loading}
          >
            Annulla
          </Button>
          <Button
            type="submit"
            form="clinic-form"
            colorScheme="teal"
            variant="solid"
            backgroundColor="brand.500"
            isLoading={loading}
          >
            Aggiungi
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddClinicModal;

import { useEffect, useMemo } from 'react';
import format from 'date-fns/format';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pane, Avatar, EditIcon } from 'evergreen-ui';
import { useQuery } from '@apollo/client';
import {
  Button,
  Flex,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Box,
} from '@chakra-ui/react';
import FullCalendar from '@fullcalendar/react';
import itLocale from '@fullcalendar/core/locales/it';
import timeGridPlugin from '@fullcalendar/timegrid';
import Title from '../../components/Title';
import TitleSmall from '../../components/TitleSmall';
import InfoRow from '../../components/InfoRow';
import Label from '../../components/Label';
import { GET_DOCTOR } from '../../graphql/queries/doctors.query';
import { GET_AVAILABILITY } from '../../graphql/queries/availability.query';

const DoctorProfile = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data, loading } = useQuery(GET_DOCTOR);
  const doctorId = data?.doctor?.id;
  const { data: availabilityData, loading: availabilityLoading } = useQuery(
    GET_AVAILABILITY,
    {
      skip: !doctorId,
      variables: { doctorId },
    }
  );

  const slots = useMemo(
    () => availabilityData?.availability?.slots || [],
    [availabilityData]
  );

  useEffect(() => {
    document.getElementsByClassName('main-content')[0].scrollTo(0, 0);
  }, [pathname]);

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  const { doctor } = data;

  return (
    <Pane
      className="profile-container"
      height="100%"
      marginLeft={91}
      marginRight={91}
    >
      <Title text="Profilo" style={{ paddingTop: 40 }} />
      <Flex
        backgroundColor="brand.300"
        width="100%"
        padding="24px"
        justifyContent="center"
        alignItems="start"
      >
        <Flex height="100%" width="100%" maxWidth="336px">
          <Tabs
            variant="soft-rounded"
            colorScheme="gray"
            orientation="vertical"
            size="lg"
          >
            <TabList w="320px">
              <Tab justifyContent="flex-start" borderRadius="10px" mb="10px">
                Informazioni
              </Tab>
              <Tab justifyContent="flex-start" borderRadius="10px" mb="10px">
                Account
              </Tab>
              <Tab justifyContent="flex-start" borderRadius="10px" mb="10px">
                Notifiche
              </Tab>
              <Tab justifyContent="flex-start" borderRadius="10px">
                Termini e condizioni
              </Tab>
            </TabList>
          </Tabs>
        </Flex>

        <Flex width="100%" height="100%" display="flex" flexDirection="column">
          <Flex justifyContent="space-between" alignItems="center">
            <TitleSmall
              text="Informazioni personali"
              color="#B5E4CA"
              style={{ marginRight: 24 }}
            />

            <Button
              size="lg"
              type="submit"
              variant="solid"
              colorScheme="teal"
              leftIcon={<EditIcon />}
              backgroundColor="brand.500"
              onClick={() => navigate('edit')}
            >
              Modifica
            </Button>
          </Flex>

          <Pane
            height={96}
            maxWidth={400}
            display="flex"
            justifyContent="flex-start"
            marginTop={32}
          >
            <Avatar
              src={doctor.name || ''}
              name={doctor.name || ''}
              size={96}
            />
          </Pane>
          <Pane
            paddingY={16}
            background="#FCFCFC"
            borderRadius={12}
            marginTop={24}
          >
            <InfoRow
              label="Nome Completo"
              placeholder="Non definito"
              element={`${doctor.name} ${doctor.surname}` || ''}
            />
            <InfoRow
              label="Codice fiscale"
              placeholder="Non definito"
              element={doctor.fiscalCode || ''}
            />
            <InfoRow label="P.IVA" placeholder="Non definito" element={null} />
            <InfoRow
              label="Cellulare"
              placeholder="Non definito"
              element={doctor.phone || ''}
            />
            <InfoRow
              label="Email"
              placeholder="Non definito"
              element={doctor.email || ''}
            />
            <InfoRow
              label="PEC"
              placeholder="Non definito"
              element={doctor.pec || ''}
            />
            <InfoRow
              label="Specializzazione"
              placeholder="Non definito"
              element={
                doctor.specializations?.length ? doctor.specializations[0] : ''
              }
            />
            <InfoRow
              label="Ordine"
              placeholder="Non definito"
              element={doctor.order}
            />
            <InfoRow
              label="N° ordine"
              placeholder="Non definito"
              element={doctor.orderNumber}
            />
            <InfoRow
              label="Servizi svolti"
              placeholder="Non definito"
              element={null}
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Label
                  label="Disponibilità generali"
                  style={{ marginTop: '32px' }}
                />
                <Label
                  className="label__gray"
                  style={{ marginTop: '12px' }}
                  label="Fai tap su uno slot per switchare da disponibile a non disponbile"
                />
              </Box>
              <Box display="flex">
                <Box
                  display="flex"
                  alignItems="center"
                  h="28px"
                  p="8px"
                  backgroundColor="#F4F4F4"
                  borderRadius="8px"
                  fontSize="12px"
                  mr="4px"
                >
                  <Box
                    w="12px"
                    h="12px"
                    background="#FFFFFF"
                    borderRadius="4px"
                  />
                  &nbsp;= Disponibile
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  h="28px"
                  p="8px"
                  borderRadius="8px"
                  fontSize="12px"
                >
                  <Box
                    w="12px"
                    h="12px"
                    background="#F4F4F4"
                    borderRadius="4px"
                  />
                  &nbsp;= Non disponibile
                </Box>
              </Box>
            </Box>

            <FullCalendar
              events={[]}
              selectable={true}
              selectMirror={true}
              selectOverlap={false}
              locale={itLocale}
              contentHeight={600}
              plugins={[timeGridPlugin]}
              initialView="timeGridWeek"
              businessHours={[
                ...(slots.length
                  ? slots
                  : [
                      {
                        dow: [1, 2, 3, 4, 5],
                        start: '23:00',
                        end: '23:00',
                      },
                    ]
                ).map(
                  (slot: { dow: number[]; start: string; end: string }) => ({
                    daysOfWeek: slot.dow,
                    startTime: slot.start,
                    endTime: slot.end,
                  })
                ),
              ]}
              hiddenDays={[0, 6]}
              slotMinTime={'08:00:00'}
              slotMaxTime={'21:00:00'}
              selectConstraint="businessHours"
              eventConstraint="businessHours"
              headerToolbar={false}
              buttonText={{
                day: 'Giorno',
                week: 'Settimana',
                month: 'Mese',
              }}
              slotDuration="01:00"
              slotLabelFormat={[
                {
                  hour: '2-digit',
                  minute: '2-digit',
                },
              ]}
              datesSet={(arg) => {
                // setState({
                //   from: arg.start.toISOString().substring(0, 10),
                //   to: arg.end.toISOString().substring(0, 10),
                // });
              }}
              dayHeaderFormat={{ weekday: 'short' }}
              allDaySlot={false}
              select={(info) => {
                // setIsAddAppointment(true);
                console.log(info);
              }}
            />
          </Pane>
        </Flex>
      </Flex>
    </Pane>
  );
};

export default DoctorProfile;

import { SVGProps } from 'react';

const VideoWhiteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 15.929v.571a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v.571l1.837-1.312c1.324-.946 3.163 0 3.163 1.627v6.228c0 1.626-1.839 2.573-3.163 1.627L17 15.93ZM5 7.5h9a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1Zm12 5.97 3 2.144V9.386l-3 2.143v1.942Z"
      fill="#fff"
    />
  </svg>
);

export default VideoWhiteIcon;

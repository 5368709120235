import * as yup from 'yup';
import { useState, forwardRef, useImperativeHandle } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import TitleSmall from '../../TitleSmall';
import GreenTagInput from '../../Inputs/GreenTagInput';
import { IModal } from '../../../interfaces/IModal';
import { validateTaxCode } from './validateTaxCode';
import { colors } from '../../../colors';

const schema = yup.object({
  name: yup.string().required('Inserisci il tuo nome e cognome'),
  surname: yup.string().required('Inserisci il tuo nome e cognome'),
  fiscalCode: yup
    .string()
    .test('isFiscalCodeValid', 'Il codice fiscale non è valido', (value) =>
      validateTaxCode(value)
    )
    .required('Inserisci il tuo codice fiscale'),
  email: yup.string().email(`L'indirizzo email inserito non è valido`),
  note: yup.string(),
});

const AddPatientModal = forwardRef(
  ({ isOpen, loading, onClose, onSave }: IModal, ref) => {
    const [tags, setTags] = useState<string[]>([]);

    const {
      handleSubmit,
      register,
      reset,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        reset();
        setTags([]);
      },
    }));

    const onSubmit = (data: any) => {
      onSave({ ...data, tags });
    };

    const nameError = errors.name?.message || errors.surname?.message;

    return (
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="brand.600">
          <ModalHeader>
            <TitleSmall text="Nuovo paziente" color={colors[200]} />
          </ModalHeader>
          <ModalCloseButton borderRadius="23px" />
          <ModalBody pb={6}>
            <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
              <Text fontSize="20px" fontWeight="semibold" color="brand.700">
                Invieremo al paziente una email di invito alla piattaforma
              </Text>
              <Text fontSize="15px" fontWeight={400} color="brand.800">
                Le informazioni che specificherai qui non verranno condivise con
                il paziente
              </Text>
              {/* FIXME: why space between? */}
              {/* FIXME: add vstack */}
              <Flex justifyContent="space-between" alignItems="flex-end">
                <FormControl
                  mr="16px"
                  mt="32px"
                  isInvalid={!!errors.name || !!errors.surname}
                >
                  <FormLabel htmlFor="name" fontSize="14px">
                    Nome completo*
                  </FormLabel>
                  <Input id="name" placeholder="Nome" {...register('name')} />
                  <FormErrorMessage>{nameError}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.name || !!errors.surname}>
                  <Input
                    id="surname"
                    placeholder="Cognome"
                    {...register('surname')}
                  />
                  <FormErrorMessage>{nameError}</FormErrorMessage>
                </FormControl>
              </Flex>
              <FormControl mt="32px" isInvalid={!!errors.fiscalCode}>
                <FormLabel htmlFor="fiscalCode" fontSize="14px">
                  Codice Fiscale*
                </FormLabel>
                <Input
                  id="fiscalCode"
                  placeholder="Codice Fiscale"
                  {...register('fiscalCode')}
                />
                <FormErrorMessage>
                  {errors.fiscalCode?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl mt="32px" isInvalid={!!errors.email}>
                <FormLabel htmlFor="email" fontSize="14px">
                  Informazioni di contatto
                </FormLabel>
                <Input
                  id="email"
                  type="email"
                  placeholder="Email"
                  {...register('email')}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl mt="32px">
                <FormLabel htmlFor="note" fontSize="14px">
                  Note
                </FormLabel>
                <Textarea
                  id="note"
                  resize="vertical"
                  backgroundColor="white"
                  border="none"
                  {...register('note')}
                />
              </FormControl>

              {/* FIXME: Tags to save */}
              <GreenTagInput
                maxTags={3}
                label="Tags"
                values={tags}
                placeholder="Inserisci i tag per poter cercare il paziente per parole chiave"
                onChange={(values) => {
                  setTags(values);
                }}
              />
            </form>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={onClose}
              backgroundColor="white"
              disabled={loading}
            >
              Annulla
            </Button>
            <Button
              type="submit"
              form="patient-form"
              colorScheme="teal"
              variant="solid"
              backgroundColor="brand.500"
              isLoading={loading}
            >
              Aggiungi
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

export default AddPatientModal;

import DatePicker from 'react-datepicker';
import CalendarInput from '../CalendarInput';
import GreenCalendarInput from '../GreenCalendarInput';
import it from 'date-fns/locale/it';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

// const monthsIT = [
//   'Gennaio',
//   'Febbraio',
//   'Marzo',
//   'Aprile',
//   'Maggio',
//   'Giugno',
//   'Luglio',
//   'Agosto',
//   'Settembre',
//   'Ottobre',
//   'Novembre',
//   'Dicembre',
// ];
// const daysIT = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];

// registerLocale('it', {
//   localize: {
//     ...it.localize,
//     month: (n: any) => monthsIT[n],
//     day: (n: any) => daysIT[n],
//   },
//   formatLong: {
//     ...it.formatLong,
//     day: []
//   },
// } as any);

interface IProps {
  value: Date | null;
  color?: 'white' | 'gray';
  icon?: 'green' | 'black';
  iconBackgroundColor?: string;
  placeholder?: string;
  dateFormat?: string;
  greenLabel?: string;
  filterDate?: (date: Date) => boolean;
  onClick?: () => void;
  onChange: (val: Date | null) => void;
  minDate?: Date;
}

const InputDatePicker = ({
  value,
  onChange,
  onClick,
  iconBackgroundColor,
  placeholder,
  color = 'gray',
  icon = 'black',
  dateFormat = 'd MMMM yyyy',
  greenLabel,
  filterDate = () => true,
  ...props
}: IProps) => {
  return (
    <DatePicker
      {...props}
      locale={it}
      selected={value}
      onChange={onChange}
      dateFormat={dateFormat}
      className={`datepicker-${color}`}
      filterDate={filterDate}
      customInput={
        icon === 'black' ? (
          <CalendarInput
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            value={value}
            iconBackgroundColor={iconBackgroundColor}
          />
        ) : (
          <GreenCalendarInput
            greenLabel={greenLabel}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            value={value}
            iconBackgroundColor={iconBackgroundColor}
          />
        )
      }
    />
  );
};

export default InputDatePicker;

import { gql } from "@apollo/client";

export const GET_DOCTOR = gql`
  query getDoctor {
    doctor {
      id
      name
      surname
      email
      fiscalCode
      phone
      pec
      order
      orderNumber
      vat
      specializations {
        id
        name
        description
      }
    }
  }
`;

export const GET_DOCTORS = gql`
  query getDoctors {
    doctors {
      id
      name
      surname
      email
      fiscalCode
      phone
      specializations {
        id
        name
        description
      }
    }
  }
`;
import * as yup from 'yup';
import { useState, useMemo, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ArrowLeftIcon } from 'evergreen-ui';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Flex,
  FormErrorMessage,
  Input,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import Title from '../../../../components/Title';
import TitleSmall from '../../../../components/TitleSmall';
import Label from '../../../../components/Label';
import { UPDATE_PATIENT } from '../../../../graphql/mutations/update-patient.mutation';
import { GET_PATIENT } from '../../../../graphql/queries/patients.query';
import { phoneRegex, emailRegex } from '../../../../regex';

import './index.scss';
import formatISO from 'date-fns/formatISO';

const schema = yup.object({
  name: yup.string().required('Wrong value'),
  surname: yup.string().required('Wrong value'),
  fiscalCode: yup.string().required('Wrong value'),
  phone: yup
    .string()
    .transform((value) => (!value ? null : value))
    .matches(phoneRegex, 'Il numero di telefono non è valido')
    .nullable(),
  pec: yup
    .string()
    .transform((value) => (!value ? null : value))
    .email("L'email non è valida")
    .matches(emailRegex, "L'email non è valida")
    .nullable(),
  birthDate: yup.string().nullable(),
  sex: yup.string().nullable(),
  addressResidence: yup.string().nullable(),
  addressDomicile: yup.string().nullable(),
  gpName: yup.string().nullable(),
  gpSurname: yup.string().nullable(),
  gpPhone: yup
    .string()
    .matches(phoneRegex, 'Il numero di telefono non è valido')
    .nullable(),
  gpEmail: yup
    .string()
    .email("L'email non è valida")
    .matches(emailRegex, "L'email non è valida")
    .nullable(),
  gpPec: yup
    .string()
    .email("L'email non è valida")
    .matches(emailRegex, "L'email non è valida")
    .nullable(),
  // anprId ?
  caregiverName: yup.string().nullable(),
  caregiverSurname: yup.string().nullable(),
  caregiverPhone: yup
    .string()
    .transform((value) => (!value ? null : value))
    .matches(phoneRegex, 'Il numero di telefono non è valido')
    .nullable(),
  caregiverEmail: yup
    .string()
    .transform((value) => (!value ? null : value))
    .email("L'email non è valida")
    .matches(emailRegex, "L'email non è valida")
    .nullable(),
  caregiverRelationship: yup.string().nullable(),
});

const EditPatient = () => {
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();

  const [state, setState] = useState<any>({});

  const { data: patient, loading } = useQuery(GET_PATIENT, {
    variables: { id: params.patientId },
  });
  const [updatePatient, mutation] = useMutation(UPDATE_PATIENT);

  const data = useMemo(() => patient?.patient || null, [patient]);

  useEffect(() => {
    if (data) {
      setState({
        ...data,
      });
    }
  }, [data]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  console.log(errors);
  const onSubmit = async (data: any) => {
    try {
      let birthDate;
      const parsedDate = data.birthDate
        ? parse(data.birthDate, 'dd.MM.yyyy', new Date())
        : null;

      if (parsedDate && isNaN(parsedDate.getTime())) {
        toast({
          title: 'La data di nascita non è valida',
          status: 'error',
          isClosable: true,
        });
        return;
      }

      if (parsedDate) {
        birthDate = formatISO(parsedDate, { representation: 'date' });
      }

      await updatePatient({
        variables: {
          id: data.id,
          input: {
            name: data.name,
            surname: data.surname,
            fiscalCode: data.ficalCode,
            birthDate,
            phone: data.phone,
            pec: data.pec,
            sex: data.sex,
            addressResidence: data.addressResidence,
            addressDomicile: data.addressDomicile,
            gpName: data.gpName,
            gpSurname: data.gpSurname,
            gpPhone: data.gpPhone,
            gpEmail: data.gpEmail,
            gpPec: data.gpPec,
            caregiver: {
              name: data.caregiverName,
              surname: data.caregiverSurname,
              email: data.caregiverEmail,
              relationship: data.caregiverRelationship,
              phone: data.caregiverPhone,
            },
          },
        },
      });
      navigate(-1);
      toast({
        title: 'Il paziente è stato aggiornato',
        status: 'success',
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: 'Errore',
        description: error.message,
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (data) {
      setState({
        ...state,
        ...data,
        caregiver: data?.caregiver ? data?.caregiver : {},
      });
      reset({
        ...state,
        ...data,
        birthDate: data.birthDate
          ? format(parseISO(data.birthDate), 'dd.MM.yyyy')
          : null,
        caregiverName: data.caregiver?.name,
        caregiverSurname: data.caregiver?.surname,
        caregiverPhone: data.caregiver?.phone,
        caregiverEmail: data.caregiver?.email,
        caregiverRelationship: data.caregiver?.relationship,
      });
    }
  }, [data]);

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box className="patient-container">
      <Title text="Modifica paziente" style={{ paddingTop: 40 }} />
      <Box className="patient-edit-body">
        <form id="patient-form" onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <TitleSmall
              text="Panoramica"
              color="#B5E4CA"
              style={{ marginRight: 24 }}
            />
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<ArrowLeftIcon />}
              onClick={() => navigate(`/patients/${params.patientId}`)}
              backgroundColor="#F2F5FA"
              borderRadius={12}
              height="48px"
            >
              Indietro
            </Button>
          </Box>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl
              mr="16px"
              mt="32px"
              isInvalid={!!errors.name || !!errors.surname}
            >
              <FormLabel htmlFor="name" fontSize="14px">
                Nome completos
              </FormLabel>
              <Input
                id="name"
                h="48px"
                placeholder="Nome"
                {...register('name')}
              />
              <FormErrorMessage>
                {errors.name?.message || null}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.name || !!errors.surname}>
              <Input
                id="surname"
                h="48px"
                placeholder="Cognome"
                {...register('surname')}
              />
              <FormErrorMessage>
                {errors.surname?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl mr="16px" mt="32px" isInvalid={!!errors.fiscal_code}>
              <FormLabel htmlFor="fiscal_code" fontSize="14px">
                Codice Fiscale
              </FormLabel>
              <Input
                id="fiscal_code"
                h="48px"
                placeholder="Codice Fiscale"
                {...register('fiscalCode')}
              />
              <FormErrorMessage>
                {errors.fiscal_code?.message || null}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.phone}>
              <FormLabel htmlFor="phone" fontSize="14px">
                Cellulare
              </FormLabel>
              <Input
                id="phone"
                h="48px"
                placeholder="Cellulare"
                {...register('phone')}
              />
              <FormErrorMessage>
                {errors.phone?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            {/* <FormControl mr="16px" mt="32px" isInvalid={!!errors.email}>
              <FormLabel htmlFor="email" fontSize="14px">
                Mail
              </FormLabel>
              <Input
                id="email"
                h="48px"
                placeholder="Mail"
                {...register('email')}
              />
              <FormErrorMessage>
                {errors.email?.message || null}
              </FormErrorMessage>
            </FormControl> */}

            <FormControl isInvalid={!!errors.pec} mt="32px">
              <FormLabel htmlFor="pec" fontSize="14px">
                PEC
              </FormLabel>
              <Input id="pec" h="48px" placeholder="PEC" {...register('pec')} />
              <FormErrorMessage>{errors.pec?.message || null}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl mr="16px" mt="32px" isInvalid={!!errors.birthDate}>
              <FormLabel htmlFor="birthDate" fontSize="14px">
                Data di nascita
              </FormLabel>
              <Input
                id="birth_date"
                h="48px"
                placeholder="Data di nascita"
                {...register('birthDate')}
              />
              <FormErrorMessage>
                {errors.birthDate?.message || null}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.gender}>
              <FormLabel htmlFor="gender" fontSize="14px">
                Sesso assegnato alla nascita
              </FormLabel>
              <Input
                id="gender"
                h="48px"
                placeholder="Sesso assegnato alla nascita"
                {...register('sex')}
              />
              <FormErrorMessage>
                {errors.gender?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl
              mr="16px"
              mt="32px"
              isInvalid={!!errors.address_residence}
            >
              <FormLabel htmlFor="address_residence" fontSize="14px">
                Luogo di residenza
              </FormLabel>
              <Input
                id="address_residence"
                h="48px"
                placeholder="Luogo di residenza"
                {...register('addressResidence')}
              />
              <FormErrorMessage>
                {errors.address_residence?.message || null}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.address_domicile}>
              <FormLabel htmlFor="address_domicile" fontSize="14px">
                Luogo di domicilio
              </FormLabel>
              <Input
                id="address_domicile"
                h="48px"
                placeholder="Luogo di domicilio"
                {...register('addressDomicile')}
              />
              <FormErrorMessage>
                {errors.address_domicile?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl mr="16px" mt="32px" isInvalid={!!errors.gp_name}>
              <FormLabel htmlFor="gp_name" fontSize="14px">
                Nome completo del medico di base
              </FormLabel>
              <Input
                id="gp_name"
                h="48px"
                placeholder="Nome del medico"
                {...register('gpName')}
              />
              <FormErrorMessage>
                {errors.gp_name?.message || null}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.gp_surname}>
              <Input
                id="gp_surname"
                h="48px"
                placeholder="Pronome del medico"
                {...register('gpSurname')}
              />
              <FormErrorMessage>
                {errors.gp_surname?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl mr="16px" mt="32px" isInvalid={!!errors.gp_phone}>
              <FormLabel htmlFor="gp_phone" fontSize="14px">
                Numero del medico
              </FormLabel>
              <Input
                id="gp_phone"
                h="48px"
                placeholder="Numero del medico"
                {...register('gpPhone')}
              />
              <FormErrorMessage>
                {errors.gp_phone?.message || null}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.gp_mail}>
              <FormLabel htmlFor="gp_mail" fontSize="14px">
                Email del medico
              </FormLabel>
              <Input
                id="gp_mail"
                h="48px"
                placeholder="Email del medico"
                {...register('gpEmail')}
              />
              <FormErrorMessage>
                {errors.gp_mail?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="flex-start" alignItems="flex-end">
            <FormControl mt="32px" isInvalid={!!errors.gpPec}>
              <FormLabel htmlFor="gpPec" fontSize="14px">
                PEC del medico
              </FormLabel>
              <Input
                id="gpPec"
                h="48px"
                placeholder="PEC del medico"
                {...register('gpPec')}
              />
              <FormErrorMessage>
                {errors.gpPec?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Box
            flex={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="32px"
          >
            <TitleSmall
              text="Caregiver"
              color="#B5E4CA"
              style={{ marginRight: 24 }}
            />
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<ArrowLeftIcon />}
              onClick={() => navigate(`/patients/${params.patientId}`)}
              backgroundColor="#F2F5FA"
              borderRadius={12}
              height="48px"
            >
              Indietro
            </Button>
          </Box>
          <Label
            label="Nome completo del Caregiver"
            style={{ marginTop: '32px' }}
          />
          <Label
            className="label__gray"
            style={{ marginTop: '12px' }}
            label="Il caregiver è qualcuno che si prende cura di te gestendo le tue visite ecc."
          />
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl mr="16px" mt="32px" isInvalid={!!errors.caregiverName}>
              <Input
                id="caregiver_first_name"
                h="48px"
                placeholder="Nome"
                {...register('caregiverName')}
              />
              <FormErrorMessage>
                {errors.caregiverName?.message || null}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.caregiverSurname}>
              <Input
                id="caregiver_last_name"
                h="48px"
                placeholder="Nome"
                {...register('caregiverSurname')}
              />
              <FormErrorMessage>
                {errors.caregiverSurname?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end">
            <FormControl
              mr="16px"
              mt="32px"
              isInvalid={!!errors.caregiverPhone}
            >
              <FormLabel htmlFor="caregiverPhone" fontSize="14px">
                Cellulare del Caregiver
              </FormLabel>
              <Input
                id="caregiverPhone"
                h="48px"
                placeholder="Inserisci cellulare"
                {...register('caregiverPhone')}
              />
              <FormErrorMessage>
                {errors.caregiverPhone?.message || null}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.caregiverEmail}>
              <FormLabel htmlFor="caregiverEmail" fontSize="14px">
                Mail del Caregiver
              </FormLabel>
              <Input
                id="caregiverEmail"
                h="48px"
                placeholder="Inserisci mail"
                {...register('caregiverEmail')}
              />
              <FormErrorMessage>
                {errors.caregiverEmail?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex justifyContent="flex-start" alignItems="flex-end">
            <FormControl mt="32px" isInvalid={!!errors.caregiverRelationship}>
              <FormLabel htmlFor="caregiver_relationship" fontSize="14px">
                Rapprto o parentela con il Caregiver
              </FormLabel>
              <Input
                id="caregiver_relationship"
                h="48px"
                placeholder="Specifica rapporto o parentela con il Caregiver"
                {...register('caregiverRelationship')}
              />
              <FormErrorMessage>
                {errors.caregiverRelationship?.message || null}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="32px"
          >
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<ArrowLeftIcon />}
              onClick={() => navigate(`/patients/${params.patientId}`)}
              height="48px"
              backgroundColor="white"
              borderRadius={12}
              disabled={mutation.loading}
            >
              Annulla
            </Button>
            <Button
              type="submit"
              colorScheme="teal"
              variant="solid"
              width="188px"
              height="48px"
              backgroundColor="brand.500"
              isLoading={mutation.loading}
            >
              Salva
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EditPatient;

import './index.css';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  text: string;
  color: string;
}

const TitleSmall = ({ text, color, ...props }: IProps) => {
  return <div className='title-sm' {...props}>
    <div className='title-tag' style={{ background: color }} />
    <span className='title-sm-text'>{text}</span>
  </div>
};

export default TitleSmall;
import {
  VideoTileGrid,
  UserActivityProvider,
  useDeviceLabelTriggerStatus,
  ContentShare,
} from "amazon-chime-sdk-component-library-react";
import { Box, Flex } from "@chakra-ui/react";
import NoMeetingRedirect from "./NoMeetingRedirect";
import BrandIcon from "../../components/Icons/BrandIcon";
import MeetingControls from "../../components/MeetingControls";
import "./index.scss";

const Meeting = () => {
  const status = useDeviceLabelTriggerStatus();
  console.log(status);

  return (
    <NoMeetingRedirect>
      <UserActivityProvider>
        <Box className="meeting-layout">
          <Box backgroundColor="brand.500" className="meeting-content">
            <Flex p="20px" alignItems="center" gridArea="header">
              <BrandIcon />
            </Flex>
            <ContentShare />
            <VideoTileGrid
              className="videos"
              layout="standard"
              noRemoteVideoView={
                <Flex
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  fontWeight="bold"
                  fontSize="20px"
                >
                  Nessun video remoto
                </Flex>
              }
            />
            <MeetingControls />
          </Box>
        </Box>
      </UserActivityProvider>
    </NoMeetingRedirect>
  );
};

export default Meeting;

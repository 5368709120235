import { Flex, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../../providers/AuthProvider/context';
import SignIn from '../SignIn';

interface IProps {
  layout: any;
}

export const PrivateRoute = ({ layout: Layout }: IProps) => {
  const { checkIsAuthenticated, inProgress, isAuthenticated } = useAuthContext();


  useEffect(() => {
    async function check() {
      await checkIsAuthenticated();
    } 

    check();
  }, []);
  
  if (isAuthenticated) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return inProgress ? (
    <Flex alignItems="center" justifyContent="center" height="100vh">
      <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
    </Flex>
  ) : (
    <SignIn />
  );
};

import { gql } from "@apollo/client";

export const GET_SANITARY_DATA = gql`
  query getSanitaryData($patientId: ID!) {
    sanitaryData(patientId: $patientId) {
      pathologies
      riskFactors
      motoricalFunction
      relatedIllnesses
      aids
      prothesis
      missingOrgans
      transplantedOrgans
      organs
      medications
      allergies
      foodReactions
      vaccinations
      pregnancies
    }
  }
`;
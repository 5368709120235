import { TextInputField, Pane, TextInputFieldProps } from 'evergreen-ui';
import './index.scss';

interface IProps extends TextInputFieldProps {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: any;
  onClick?: () => void;
  className?: string;
  styles?: any;
  greenLabel?: string;
  innerRef?: any;
}

const InputBlock = ({
  label,
  placeholder,
  value,
  onChange,
  onClick,
  className = '',
  greenLabel = '',
  innerRef,
  ...props
}: any) => {
  const getLabel = () => {
    return label ? <div className="label">{label}</div> : '';
  };

  // value is undefined
  // props types are incompatible

  if (greenLabel && value) {
    return (
      <Pane position="relative">
        <Pane position="absolute" top={12} left={20} className="green-label">
          {greenLabel.toUpperCase()}
        </Pane>
        <TextInputField
          innerRef={innerRef}
          className={`input-box input-box__green-label ${className}`}
          label={getLabel()}
          placeholder={placeholder || ''}
          value={value}
          onChange={onChange}
          marginTop={32}
          marginBottom={0}
          {...(onClick ? { onClick } : {})}
          {...props}
        />
      </Pane>
    );
  }

  return (
    <TextInputField
      className={`input-box ${className}`}
      label={getLabel()}
      placeholder={placeholder || ''}
      value={value}
      onChange={onChange}
      marginTop={32}
      marginBottom={0}
      {...(onClick ? { onClick } : {})}
      {...props}
    />
  );
};

export default InputBlock;

import { Pane, PaneOwnProps, BoxOwnProps } from 'evergreen-ui';

interface IProps {
  text: string;
}

const AnchorItem = ({
  text,
  ...props
}: IProps & PaneOwnProps & BoxOwnProps) => {
  return (
    <Pane height={40} paddingY={8} paddingX={16} {...props}>
      {text}
    </Pane>
  );
};

export default AnchorItem;

import './index.css';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  text: string;
}

const Title = ({ text, ...props }: IProps) => {
  return <div className='title' {...props}>{text}</div>
};

export default Title;
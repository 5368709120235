import { Pane } from 'evergreen-ui';
import CheckCircledIcon from '../Icons/CheckCircledIcon';
import './index.scss';

const FeaturePoint = ({ text }: { text: string }) => (
  <Pane display="flex" alignItems="center">
    <div>
      <CheckCircledIcon />
    </div>
    <div className="feature-point">{text}</div>
  </Pane>
);

export default FeaturePoint;

import { Box, Button, Flex } from '@chakra-ui/react';
import TitleSmall from '../../../../components/TitleSmall';

import { colors } from '../../../../colors';
import { PlusIcon } from 'evergreen-ui';
import EditIcon from '../../../../components/Icons/EditIcon';
import InfoRow from '../../../../components/InfoRow';

const Therapies = () => {
  return (
    <Box p="24px" borderRadius="8px" bg="#FCFCFC">
      <Flex justifyContent="space-between" alignItems="center">
        <TitleSmall
          text="Terapie"
          color={colors[100]}
          style={{ marginRight: 24 }}
        />
        <Button
          colorScheme="teal"
          variant="solid"
          //   onClick={() => setIsAddAppointment(true)}
          leftIcon={<PlusIcon />}
          height="48px"
          backgroundColor="brand.500"
        >
          Aggiungi terapia
        </Button>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mt="32px"
      >
        <TitleSmall
          text="Informazioni"
          color="#CABDFF"
          style={{ marginRight: 24 }}
        />
        <Button
          colorScheme="teal"
          variant="solid"
          //   onClick={() => navigate('edit')}
          leftIcon={<EditIcon />}
          width="114px"
          height="48px"
          backgroundColor="brand.500"
        >
          Modifica
        </Button>
      </Flex>
      <Box p="16px" bg="rgba(244, 244, 244, 0.5)" borderRadius={12} mt="24px">
        <InfoRow
          label="Terapie farmacologiche croniche"
          placeholder="Non definito"
          element={null}
        />
        <InfoRow
          label="Terapie farmacologiche"
          placeholder="Non definito"
          element={null}
        />
        <InfoRow
          label="Piani di cura"
          placeholder="Non definito"
          element={null}
        />
        <InfoRow
          label="Trattamenti"
          placeholder="Non definito"
          element={null}
        />
        <InfoRow
          label="Esenzioni causa patologia"
          placeholder="Non definito"
          element={null}
        />
        <InfoRow
          label="Reti di patologia"
          placeholder="Non definito"
          element={null}
        />
        <InfoRow label="ADI" placeholder="Non definito" element={null} />
        <InfoRow label="ADP" placeholder="Non definito" element={null} />
      </Box>
    </Box>
  );
};

export default Therapies;

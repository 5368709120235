import { CrossIcon, Menu } from 'evergreen-ui';
import { IconButton, Box } from '@chakra-ui/react';
import Title from '../../../Title';
import { IPatient } from '../../../../interfaces/IPatient';

const InvitationSubModal = ({
  isOpen,
  onClose,
  onSelect,
  patients,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (patient: IPatient) => void;
  patients: IPatient[];
}) => {
  return isOpen ? (
    <Box
      w={340}
      bg="#F1F4F9"
      pos="absolute"
      left={396}
      borderRadius="16px"
      p="24px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="24px"
      >
        <Title
          style={{ fontSize: '20px', marginBottom: '0px' }}
          text="Invitati"
        />
        <IconButton
          border="none"
          aria-label="close"
          icon={<CrossIcon style={{ height: '20px', width: '20px' }} />}
          onClick={onClose}
          background="#EFEFEF"
          borderRadius="50%"
        />
      </Box>
      <Box className="submodal-body" overflowY="scroll" maxH={328}>
        <Menu>
          <Menu.Group>
            {patients.map((patient) => (
              <Menu.Item
                key={patient.id}
                onSelect={() => onSelect(patient)}
                borderRadius={4}
                height={40}
              >
                {patient.name} {patient.surname}
              </Menu.Item>
            ))}
          </Menu.Group>
        </Menu>
      </Box>
    </Box>
  ) : null;
};

export default InvitationSubModal;

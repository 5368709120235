import { Button, ButtonOwnProps } from 'evergreen-ui';
import './index.scss';

interface IProps extends ButtonOwnProps {
  title: string;
  onClick?: () => void;
  iconBefore?: false | JSX.Element | React.ElementType<any> | null | undefined
}

const DefaultButton = ({ title, onClick, iconBefore = null, ...rest }: any) => {
  return <Button
    className='default-button'
    height="48px !important"
    width="auto"
    fontWeight="700 !important"
    letterSpacing="-0.01em"
    paddingY={12}
    paddingX={20}
    background="#FCFCFC"
    border="2px solid #EFEFEF !important"
    borderRadius={12}
    onClick={onClick}
    iconBefore={iconBefore}
    {...rest}
  >
    {title}
  </Button>
};

export default DefaultButton;

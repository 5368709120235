import { Button, Box } from '@chakra-ui/react';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import it from 'date-fns/locale/it';
import { useNavigate } from 'react-router-dom';
import TitleSmall from '../../../../components/TitleSmall';
import GreenButton from '../../../../components/Buttons/GreenButton';
import EditIcon from '../../../../components/Icons/EditIcon';
import InfoRow from '../../../../components/InfoRow';
import { colors } from '../../../../colors';

import './index.scss';

const Review = ({ patient }: any) => {
  const navigate = useNavigate();
  
  return (
    <Box p="24px" borderRadius="8px" bg="#FCFCFC">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TitleSmall
          text="Panoramica"
          color={colors[100]}
          style={{ marginRight: 24 }}
        />
        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() => navigate('edit')}
          leftIcon={<EditIcon />}
          width="114px"
          height="48px"
          backgroundColor="brand.500"
        >
          Modifica
        </Button>
      </Box>
      <Box p="16px" bg="rgba(244, 244, 244, 0.5)" borderRadius={12} mt="24px">
        <InfoRow
          label="Codice Fiscale"
          placeholder="Non definito"
          element={patient?.fiscalCode}
        />
        <InfoRow
          label="Cellulare"
          placeholder="Non definito"
          element={patient?.phone}
        />
        <InfoRow
          label="Mail"
          placeholder="Non definito"
          element={patient?.email}
        />
        <InfoRow
          label="PEC"
          placeholder="Non definito"
          element={patient?.pec}
        />
        <InfoRow
          label="Data di nascita"
          placeholder="Non definito"
          element={patient?.birthDate ? format(parseISO(patient?.birthDate), 'dd MMMM yyyy', { locale: it }) : null}
        />
        <InfoRow
          label="Sesso assegnato alla nascita"
          placeholder="Non definito"
          element={patient?.sex}
        />
        <InfoRow
          label="Luogo di residenza"
          placeholder="Non definito"
          element={patient?.addressResidence}
        />
        <InfoRow
          label="Luogo di domicilio"
          placeholder="Non definito"
          element={patient?.addressDomicile}
        />
        <InfoRow
          label="Nome completo del medico di base"
          placeholder="Non definito"
          element={`${patient?.gpName} ${patient?.gpSurname}`}
        />
        <InfoRow
          label="Cellulare del medico di bases"
          placeholder="Non definito"
          element={patient?.gpPhone}
        />
        <InfoRow
          label="Mail del medico di base"
          placeholder="Non definito"
          element={patient?.gpEmail}
        />
        <InfoRow
          label="PEC del medico di base"
          placeholder="Non definito"
          element={patient?.gpPec}
        />
        <InfoRow
          label="ID ANPR"
          placeholder="Non definito"
          element={patient?.anprId}
        />
        <InfoRow
          label="Paziente dal"
          placeholder="Invito in sospeso"
          element={
            patient?.createdAt
              ? format(parseISO(patient.createdAt), 'dd MMMM yyyy', { locale: it })
              : null
          }
        />
        <InfoRow
          label="Ultima attività sull’app"
          placeholder="Invito in sospeso"
          element={null}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt="32px"
      >
        <TitleSmall
          text="Caregiver"
          color="#CABDFF"
          style={{ marginRight: 24 }}
        />
        <GreenButton
          title="Modifica"
          iconBefore={EditIcon}
          borderRadius={12}
          onClick={() => navigate('edit')}
        />
      </Box>
      <Box p="16px" bg="rgba(244, 244, 244, 0.5)" borderRadius={12} mt="24px">
        <InfoRow
          label="Caregiver"
          placeholder="Non definito"
          element={`${patient.caregiver?.name || ''} ${
            patient.caregiver?.surname || ''
          }`}
        />
        <InfoRow
          label="Cellulare del caregiver"
          placeholder="Non definito"
          element={patient.caregiver?.phone || ''}
        />
        <InfoRow
          label="Parentela/rapporto con il caregiver"
          placeholder="Non definito"
          element={patient.caregiver?.relationship || ''}
        />
        <InfoRow
          label="Mail del caregiver"
          placeholder="Non definito"
          element={patient.caregiver?.email || ''}
        />
      </Box>
    </Box>
  );
};

export default Review;

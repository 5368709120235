import {
  Avatar,
  Button,
  Flex,
  Grid,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tag,
} from '@chakra-ui/react';
import { NotificationsIcon, SearchIcon } from 'evergreen-ui';
import { ChangeEvent } from 'react';

const Header = ({
  logoutInProgress,
  displayName,
  handleLogout,
  handleAvatarClick,
}: {
  logoutInProgress: boolean;
  displayName: string;
  handleLogout: () => void;
  handleAvatarClick: () => void;
}) => {
  return (
    <Grid
      height="auto"
      gridArea="header"
      backgroundColor="brand.600"
      className="header-container"
    >
      <Flex
        height="96px"
        backgroundColor="brand.300"
        marginX="50px"
        paddingY="24px"
        paddingX="40px"
        alignItems="center"
      >
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <InputGroup size="lg" w="360px">
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray" />}
            />
            <Input
              size="lg"
              placeholder="Cerca"
              backgroundColor="brand.400 !important"
              onChange={(e: ChangeEvent<HTMLInputElement>) => console.log(e)}
              style={{ caretColor: '#2A85FF' }}
            />
            <InputRightElement width="4.5rem">
              <Tag
                color="black"
                fontWeight="bold"
                backgroundColor="white"
                variant="solid"
                p={2}
              >
                ⌘ + F
              </Tag>
            </InputRightElement>
          </InputGroup>

          <HStack spacing="24px">
            <IconButton
              variant="ghost"
              colorScheme="gray"
              aria-label="notifications"
              icon={<NotificationsIcon />}
            />
            <Avatar name={displayName} onClick={handleAvatarClick} />
            <Button variant="ghost" colorScheme="gray" onClick={handleLogout} isLoading={logoutInProgress}>
              Sign Out
            </Button>
          </HStack>
        </Flex>
      </Flex>
    </Grid>
  );
};

export default Header;

import { SVGProps } from 'react';

const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.573 8.882a1 1 0 1 0-1.146 1.638l1.146-1.638ZM10.5 12.85l-.573.82a1 1 0 0 0 1.146 0l-.573-.82Zm5.073-2.33a1 1 0 0 0-1.146-1.64l1.146 1.64Zm-13.2-4.944-.514-.858.515.858Zm7.2-4.32L9.06.399l.515.858Zm1.853 0 .515-.858-.515.858Zm7.2 4.32-.514.857.514-.857Zm-13.2 4.943 4.5 3.15 1.147-1.638-4.5-3.15-1.146 1.638Zm5.647 3.15 4.5-3.15-1.146-1.638-4.5 3.15 1.146 1.638ZM2.5 16.9V7.12h-2v9.78h2Zm0-9.78a.8.8 0 0 1 .388-.686L1.86 4.719A2.8 2.8 0 0 0 .5 7.12h2Zm.388-.686 7.2-4.32L9.06.399l-7.2 4.32 1.03 1.715Zm7.2-4.32a.8.8 0 0 1 .824 0L11.94.399a2.8 2.8 0 0 0-2.882 0l1.03 1.715Zm.824 0 7.2 4.32 1.029-1.715-7.2-4.32-1.03 1.715Zm7.2 4.32a.8.8 0 0 1 .388.686h2a2.8 2.8 0 0 0-1.36-2.401l-1.028 1.715Zm.388.686v9.78h2V7.12h-2Zm0 9.78a.8.8 0 0 1-.8.8v2a2.8 2.8 0 0 0 2.8-2.8h-2Zm-.8.8H3.3v2h14.4v-2Zm-14.4 0a.8.8 0 0 1-.8-.8h-2a2.8 2.8 0 0 0 2.8 2.8v-2Z"
      fill="#3F3F3F"
    />
  </svg>
);

export default MailIcon;

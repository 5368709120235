import { useState, useMemo, useEffect, forwardRef } from 'react';
import format from 'date-fns/format';
import { useQuery } from '@apollo/client';
import { CrossIcon, Dialog, Textarea } from 'evergreen-ui';
import { Box, Button, IconButton } from '@chakra-ui/react';
import { IModal } from '../../../interfaces/IModal';
import InputDatePicker from '../../Inputs/InputDatePicker';
import InputBlock from '../../Inputs/InputBlock';
import ToggleInput from '../../Inputs/ToggleInput';
import TitleSmall from '../../TitleSmall';
import TypeInvitationModal from '../SubModals/TypeInvitationModal';
import TimeSubModal from '../SubModals/TimeSubModal';
import GreenCalendarInput from '../../Inputs/GreenCalendarInput';
import { IPatient } from '../../../interfaces/IPatient';
import { getAvailableTime } from '../../../utils';
import { GET_PATIENTS } from '../../../graphql/queries/patients.query';
import { ICalendarEvent } from '../../../interfaces/ICalendarEvent';

const initialState = {
  isOpenInvitation: false,
  isOpenType: false,
  isOpenTime: false,
};

const EditAppointmentModal = ({
  event,
  events,
  onClose,
  loading,
  isOpen,
  onSave,
  patientId,
}: IModal & {
  event: ICalendarEvent & { patient: IPatient };
  events: ICalendarEvent[];
  patientId?: number | null;
}) => {
  const [state, setState] = useState<any>(initialState);
  const [availableTime, setAvailableTime] = useState<string[]>([]);
  const [formData, setFormData] = useState<any>({
    id: event.id,
    patientId: event.patient.id,
    service: event.serviceId,
    isOnline: Boolean(event.online),
    date: new Date(event.startDateTime),
    startTime: format(new Date(event.startDateTime), 'HH:mm'),
    note: event.note,
  });

  const patientsQuery = useQuery(GET_PATIENTS);

  const data: IPatient[] = useMemo(
    () => patientsQuery.data?.patients || [],
    [patientsQuery.data]
  );
  // const serviceList: IClinicServices[] = useMemo(
  //   () => clinicServiceList.data?.data || [],
  //   [clinicServiceList]
  // );

  const serviceList: any = useMemo(() => {
    return [
      {
        id: 1,
        hospital_id: 1,
        name: 'Visita introduttiva',
        price: '20.00',
        duration: 30,
      },
      {
        id: 2,
        hospital_id: 1,
        name: 'Visita di follow-up',
        price: '10.00',
        duration: 20,
      },
    ];
  }, []);

  const currentPatient = useMemo(() => {
    if (formData.patientId) {
      const patient = data.find((p) => p.id === formData.patientId);

      return patient ? `${patient.name} ${patient.surname}` : '';
    } else {
      return event.patient
        ? `${event.patient.name} ${event.patient.surname}`
        : '';
    }
  }, [formData, data, event.patient]);

  useEffect(() => {
    if (serviceList.length) {
      setFormData((prevState: any) => ({
        ...prevState,
        service: {
          ...prevState.service,
          ...serviceList.find((service: any) => service.id == event.serviceId),
        },
      }));
    }
  }, [serviceList, event.serviceId]);

  useEffect(() => {
    if (!isOpen) {
      setState({
        ...initialState,
      });
      setFormData((prevState: any) => ({
        ...prevState,
        patientId: patientId || prevState.patientId,
      }));
    } else {
      setFormData((prevState: any) => ({ ...prevState, patientId }));
    }
  }, [isOpen, patientId]);

  useEffect(() => {
    if (formData.service) {
      setAvailableTime(
        getAvailableTime(formData.date, events, +formData.service.duration)
      );
    }
  }, [formData.date, formData.service, event, events]);

  const handleChange = (key: string, value: any) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const ModalHeader = () => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
      >
        <TitleSmall text="Modify appuntamento" color="#FFBC99" />
        <IconButton
          border="none"
          aria-label="close"
          icon={<CrossIcon style={{ height: '20px', width: '20px' }} />}
          onClick={onClose}
          background="#EFEFEF"
          variant="circle"
        />
      </Box>
    );
  };

  return (
    <Dialog
      isShown={isOpen}
      hasHeader={false}
      hasFooter={false}
      header={ModalHeader}
      containerProps={{ background: '#F1F4F9' }}
      width={376}
      onCloseComplete={onClose}
    >
      <TypeInvitationModal
        isOpen={state.isOpenType}
        onSelect={(service) => {
          handleChange('service', service);
          setState({
            ...state,
            isOpenType: false,
          });
        }}
        onClose={() =>
          setState({
            ...state,
            isOpenType: false,
          })
        }
        values={serviceList}
      />
      <TimeSubModal
        title="Ora"
        timeList={availableTime}
        isOpen={state.isOpenTime}
        onSelect={(time) => {
          handleChange('startTime', time);
          setState({
            ...state,
            isOpenTime: false,
          });
        }}
        onClose={() =>
          setState({
            ...state,
            isOpenTime: false,
          })
        }
      />
      <Box>
        <div className="modal-text">
          Scegli una data e un'ora per la visita. Vedrai solo gli slot coerenti
          con le tue disponibilità.
        </div>
        <Box mt="24px">
          <InputBlock
            className="input-box_green"
            placeholder="Invitati"
            greenLabel="Invitati"
            value={currentPatient}
            marginTop={24}
            disabled={true}
          />
          <InputBlock
            className="input-box_green"
            placeholder="Tipo di appuntamento"
            greenLabel="Tipo di appuntamento"
            value={
              formData.service
                ? `${formData.service.name} - ${formData.service.duration} minuti`
                : ''
            }
            onChange={() => console.log('onChange')}
            marginTop={24}
            onClick={() =>
              setState({
                ...initialState,
                isOpenType: true,
              })
            }
          />
          <ToggleInput
            marginTop={24}
            marginLeft={20}
            title="Appuntamento online"
            checked={formData.isOnline}
            onChange={(checked) => handleChange('isOnline', checked)}
          />
          <InputDatePicker
            icon="green"
            greenLabel="Data"
            dateFormat="EEEE d MMMM yyyy"
            placeholder="Data"
            value={formData.date}
            iconBackgroundColor="#FFFFFF"
            onChange={(val) => handleChange('date', val)}
            filterDate={isWeekday}
            minDate={new Date()}
          />
          <GreenCalendarInput
            placeholder="Ora"
            greenLabel="Ora"
            value={formData.startTime}
            iconBackgroundColor="#FFFFFF"
            onChange={() => {
              console.log('onChange');
            }}
            onClick={() =>
              setState({
                ...initialState,
                isOpenTime: true,
              })
            }
          />
          <Box mt="24px">
            <Textarea
              id="textarea-patient-note"
              className="textarea-base"
              required={false}
              value={formData.note}
              height="112px !important"
              onChange={(event: any) =>
                handleChange('note', event.target.value)
              }
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt="16px"
        >
          <Button
            colorScheme="gray"
            variant="outline"
            onClick={onClose}
            backgroundColor="white"
            disabled={loading}
          >
            Annulla
          </Button>
          <Button
            colorScheme="teal"
            variant="solid"
            backgroundColor="brand.500"
            isLoading={loading}
            onClick={() => onSave(formData)}
          >
            Salva
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default forwardRef(EditAppointmentModal);

import CompassIcon from '../Icons/CompassIcon';
import PatientIcon from '../Icons/PatientIcon';
import CalendarIcon from '../Icons/CalendarIcon';
import DoctorsIcon from '../Icons/DoctorsIcon';
import ClinicIcon from '../Icons/ClinicIcon';
import AgencyIcon from '../Icons/AgencyIcon';

interface TabInfo {
  key: number;
  title: string;
  route: string;
  Icon: any;
}

interface Tabs {
  patient: TabInfo[];
  doctor: TabInfo[];
  clinic_admin: TabInfo[];
  agency_admin: TabInfo[];
  super_admin: TabInfo[];
}

const tabs: Tabs = {
  patient: [
    {
      key: 0,
      title: 'Calendario',
      route: '/calendar',
      Icon: CalendarIcon,
    },
    // {
    //   key: 2,
    //   title: 'Percorsi',
    //   route: '',
    //   Icon: CompassIcon,
    // },
  ],
  doctor: [
    {
      key: 0,
      title: 'Calendario',
      route: '/calendar',
      Icon: CalendarIcon,
    },
    {
      key: 1,
      title: 'Pazienti',
      route: '/patients',
      Icon: PatientIcon,
    },
    // {
    //   key: 2,
    //   title: 'Cliniche',
    //   route: '/clinics',
    //   Icon: ClinicIcon,
    // },
    // {
    //   key: 2,
    //   title: 'Percorsi',
    //   route: '',
    //   Icon: CompassIcon,
    // },
  ],
  clinic_admin: [
    {
      key: 0,
      title: 'Servizi',
      route: '',
      Icon: CalendarIcon,
    },
    {
      key: 1,
      title: 'Dottori',
      route: '/doctors',
      Icon: DoctorsIcon,
    },
    {
      key: 2,
      title: 'Pazienti',
      route: '/patients',
      Icon: PatientIcon,
    },
  ],
  agency_admin: [
    {
      key: 0,
      title: 'Cliniche',
      route: '',
      Icon: ClinicIcon,
    },
    {
      key: 1,
      title: 'Dottori',
      route: '/doctors',
      Icon: DoctorsIcon,
    },
    {
      key: 2,
      title: 'Pazienti',
      route: '/patients',
      Icon: PatientIcon,
    },
  ],
  super_admin: [
    {
      key: 0,
      title: 'Calendario',
      route: '/calendar',
      Icon: CalendarIcon,
    },
    // {
    //   key: 0,
    //   title: 'Agencies',
    //   route: '/agencies',
    //   Icon: AgencyIcon,
    // },
    {
      key: 1,
      title: 'Cliniche',
      route: '/clinics',
      Icon: ClinicIcon,
    },
    {
      key: 2,
      title: 'Dottori',
      route: '/doctors',
      Icon: DoctorsIcon,
    },
    {
      key: 3,
      title: 'Pazienti',
      route: '/patients',
      Icon: PatientIcon,
    },
  ],
};

export default tabs;

import { BoxOwnProps, Pane, PaneOwnProps } from 'evergreen-ui';

const GrayLine = (props: PaneOwnProps & BoxOwnProps) => {
  return <Pane
    height={1}
    background="#EFEFEF"
    borderRadius={1}
    paddingTop={1}
    width="100%"
    {...props}
  />;
};

export default GrayLine;
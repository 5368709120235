import { gql } from '@apollo/client';

export const UPDATE_PATIENT = gql`
  mutation updatePatient($id: ID!, $input: PatientUpdateInput!) {
    updatePatient(id: $id, input: $input) {
      id
      caregiver {
        id
      }
    }
  }
`;

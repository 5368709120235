import { gql } from '@apollo/client';

export const GET_USER = gql`
  query getUser {
    me {
      id
      email
      name
      surname
      role
      pec
      profilePhoto
    }
  }
`;

import { SVGProps } from 'react';

const GreenCalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.667.5v6.667M16.333.5v6.667M5 10.5h4m8 0h-4m-8 4h4m4 0h4M3 3.833h16c.736 0 1.333.597 1.333 1.334V18.5c0 .736-.596 1.333-1.333 1.333H3A1.333 1.333 0 0 1 1.667 18.5V5.167c0-.737.597-1.334 1.333-1.334Z"
      stroke="#66E3C3"
      strokeWidth={1.5}
    />
  </svg>
);

export default GreenCalendarIcon;

import { SVGProps } from 'react';

const PhoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.825 6.998 7.317 4.943a.327.327 0 0 0-.442-.082C4.903 6.138 4.361 8.087 5.152 9.524c.865 1.572 2.2 3.5 4.247 5.547 2.048 2.048 3.975 3.382 5.547 4.248 1.437.791 3.387.249 4.663-1.724a.327.327 0 0 0-.081-.441l-2.056-1.508a1 1 0 0 0-1.039-.088l-.819.41c-.697.348-1.652.47-2.557.005-.604-.31-1.536-.902-2.597-1.963-1.06-1.06-1.652-1.992-1.963-2.597-.465-.904-.343-1.86.006-2.557l.41-.819a1 1 0 0 0-.088-1.039ZM5.789 3.182c-2.54 1.644-3.849 4.656-2.39 7.307.953 1.73 2.4 3.81 4.586 5.996 2.186 2.186 4.266 3.633 5.997 4.586 2.65 1.46 5.663.151 7.306-2.39a2.328 2.328 0 0 0-.577-3.14l-2.056-1.508a3 3 0 0 0-3.116-.264l-.82.41a.822.822 0 0 1-.747.016c-.43-.221-1.19-.692-2.098-1.599-.907-.907-1.377-1.668-1.598-2.098a.821.821 0 0 1 .016-.747l.41-.82a3 3 0 0 0-.265-3.115L8.93 3.76a2.327 2.327 0 0 0-3.141-.578Z"
      fill="#3F3F3F"
    />
  </svg>
);

export default PhoneIcon;

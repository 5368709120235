import { Pane, Switch, BoxOwnProps } from 'evergreen-ui';

import './index.scss';

interface IProps extends BoxOwnProps {
  title: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ToggleInput = ({ title, checked, onChange, ...props }: IProps) => {
  return (
    <Pane
      className="toggle-input"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...(props as any)}
    >
      <Pane>{title}</Pane>
      <Switch
        className={checked ? 'switch-input_on' : 'switch-input_off'}
        height={24}
        checked={checked}
        onChange={() => onChange(!checked)}
      />
    </Pane>
  );
};

export default ToggleInput;

import { SVGProps } from 'react';

const EditGrayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#a)"
      stroke="#3C3F4D"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8.75 3.5H3.5A1.5 1.5 0 0 0 2 5v10.5A1.5 1.5 0 0 0 3.5 17H14a1.5 1.5 0 0 0 1.5-1.5v-5.25" />
      <path d="M14.375 2.375a1.591 1.591 0 1 1 2.25 2.25L9.5 11.75l-3 .75.75-3 7.125-7.125Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5 .5)" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EditGrayIcon;

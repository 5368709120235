import { gql } from '@apollo/client';

export const GET_PATIENTS = gql`
  query getPatients {
    patients {
      id
      email
      name
      surname
      phone
      status
      tags
    }
  }
`;

export const GET_PATIENT = gql`
  query getPatient($id: ID!) {
    patient(id: $id) {
      id
      email
      name
      surname
      birthDate
      fiscalCode
      phone
      tags
      sex
      addressResidence
      addressDomicile
      gpName
      gpSurname
      gpPhone
      gpEmail
      gpPec
      anprId
      pec
      status
      caregiver {
        name
        surname
        relationship
        email
        phone
      }
    }
  }
`;

import { useNavigate } from 'react-router-dom';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { DeviceLabels, useMeetingManager } from 'amazon-chime-sdk-component-library-react';

export const useJoinMeeting = () => {
  const navigate = useNavigate();
  const meetingManager = useMeetingManager();

  const joinMeeting = async (data: any) => {
    const meetingSessionConfiguration = new MeetingSessionConfiguration(data.meeting, data.attendee);
    const options = {
      deviceLabels: DeviceLabels.AudioAndVideo,
    };

    // Use the join API to create a meeting session using DeviceLabels.Video
    await meetingManager.join(
      meetingSessionConfiguration,
      options
    );

    // At this point you can let users setup their camera device
    // Or by default the SDK selects the first device in the list for the kind indicated by `deviceLabels`

    // Start the session to join the meeting
    await meetingManager.start();
    navigate('/meeting');
  };

  return { joinMeeting };
};

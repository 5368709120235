import { SVGProps } from 'react';

const BucketIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.425 4.16071V2.71429C5.425 2.18173 5.82794 1.75 6.325 1.75H9.925C10.4221 1.75 10.825 2.18173 10.825 2.71429V4.16071M1.375 4.64286H14.875M2.725 4.64286V14.2857C2.725 14.8183 3.12794 15.25 3.625 15.25H12.625C13.1221 15.25 13.525 14.8183 13.525 14.2857V4.64286M8.125 8.01786V12.8393M5.425 9.94643V12.8393M10.825 9.94643V12.8393"
      stroke="#3F3F3F"
      strokeWidth="1.8"
      strokeLinecap="round"
    />
  </svg>
);

export default BucketIcon;

import { IconButton, CrossIcon, Menu } from 'evergreen-ui';
import { Box } from '@chakra-ui/react';
import Title from '../../../Title';

const TimeSubModal = ({
  timeList,
  isOpen,
  onClose,
  onSelect,
  title,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (time: string) => void;
  timeList: any[];
  title: string;
}) => {
  return isOpen ? (
    <Box
      w={340}
      bg="#F1F4F9"
      pos="absolute"
      left={396}
      borderRadius="16px"
      p="24px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="24px"
      >
        <Title style={{ fontSize: '20px', marginBottom: '0px' }} text={title} />
        <IconButton
          border="none"
          aria-label="close"
          icon={<CrossIcon style={{ height: '20px', width: '20px' }} />}
          onClick={onClose}
          background="#EFEFEF"
          borderRadius="50%"
        />
      </Box>
      <Box className="submodal-body" overflowY="scroll" maxH={328}>
        <Menu>
          <Menu.Group>
            {timeList.map((time) => (
              <Menu.Item
                key={time}
                onSelect={() => onSelect(time)}
                borderRadius={4}
                height={40}
              >
                {time}
              </Menu.Item>
            ))}
          </Menu.Group>
        </Menu>
      </Box>
    </Box>
  ) : null;
};

export default TimeSubModal;

import { gql } from "@apollo/client";

export const CREATE_DOCTOR = gql`
  mutation createDoctor($input: DoctorInput!) {
    createDoctor(input: $input) {
      id
      name
      surname
      email
      phone
    }
  }
`;
import { Box } from '@chakra-ui/react';
import { TagInput, TagInputOwnProps } from 'evergreen-ui';
import Label from '../../Label';

import './index.scss';

interface IProps extends TagInputOwnProps {
  values: string[];
  onChange: (values: string[]) => void;
  className?: string;
  styles?: any;
  placeholder?: string;
  label: string;
  maxTags: number;
}

const GreenTagInput = ({
  label,
  values,
  onChange,
  placeholder = '',
  maxTags,
  ...props
}: IProps) => {
  const handleChange = (values: string[]) => {
    if (values.length <= maxTags) {
      onChange(values);
    }
  };

  return (
    <Box
      mt="32px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="8px"
      >
        <Label label={label} />
        <div className="tag-count">
          <div>{values.length}</div>
          <div className="tag-count__max">/{maxTags} tags</div>
        </div>
      </Box>
      <TagInput
        className="tag-input-box"
        values={values}
        width="100%"
        marginBottom={0}
        onChange={handleChange}
        inputProps={{ placeholder }}
        {...props}
      />
    </Box>
  );
};

export default GreenTagInput;

import { gql } from "@apollo/client";

export const JOIN_MEETING = gql`
  mutation JoinMeeting($id: ID!) {
    joinMeeting(id: $id) {
      meeting {
        MeetingId
        ExternalMeetingId
        MediaRegion
        MediaPlacement {
          AudioHostUrl
          AudioFallbackUrl
          ScreenDataUrl
          ScreenSharingUrl
          ScreenViewingUrl
          SignalingUrl
          TurnControlUrl
          EventIngestionUrl
        }
      }
      attendee {
        ExternalUserId
        AttendeeId
        JoinToken
      }
    }
  }
`;
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  VStack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Hub } from 'aws-amplify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FeaturePoint from '../../components/FeaturePoint';
import CreateAccountForm from './Forms/CreateAccountForm';
// import ConfirmIdentityPhoneForm from './Forms/ConfirmIdentityPhoneForm';
import ConfirmIdentityCodeForm from './Forms/ConfirmIdentityCodeForm';
// import Welcome from './Forms/Welcome';
import { text } from '../../utils/text';
import { User } from '../../types/User';
import DoctorIcon from '../../components/Icons/DoctorIcon';
import { useAuthContext } from '../../providers/AuthProvider/context';

const SignUp = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { signUp, confirmSignUp, resendConfirmationCode, inProgress } =
    useAuthContext();

  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');

  const type = (searchParams.get('type') as User) || 'patient';
  const { consentLinkText, consentText, features } = text[type];

  useEffect(() => {
    const listenToAutoSignInEvent = () => {
      Hub.listen('auth', ({ payload }) => {
        const { event } = payload;
        console.log(payload);

        if (event === 'autoSignIn') {
          const user = payload.data;
          console.log('AUTO SIGNIN');
          navigate('/profile');
        } else if (event === 'autoSignIn_failure') {
          console.log('AUTOSIGNIN FAILED');
          navigate('/signin');
        }
      });
    };

    listenToAutoSignInEvent();

    return () => Hub.remove('auth', listenToAutoSignInEvent);
  }, []);

  const renderForm = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CreateAccountForm
            consent={{ consentLinkText, consentText }}
            isLoading={inProgress}
            onSubmit={async ({ email, password }) => {
              try {
                setEmail(email);
                await signUp(email, password);
                setStep(1);
              } catch (e) {
                toast({
                  title: e,
                  status: 'error',
                  isClosable: true,
                });
               }
            }}
          />
        );
      // case 1:
      //   return (
      //     <ConfirmIdentityPhoneForm
      //       onSubmit={(values) => {
      //         console.log(values);
      //         setStep(2);
      //       }}
      //     />
      //   );
      case 1:
        return (
          <ConfirmIdentityCodeForm
            isLoading={inProgress}
            onSubmit={async ({ code }) => {
              try {
                await confirmSignUp(email, code);
              } catch (e) {
                toast({
                  title: e,
                  status: 'error',
                  isClosable: true,
                });
              }
            }}
            onSendAgain={async () => {
              try {
                await resendConfirmationCode(email);
              } catch (e) {
                toast({
                  title: e,
                  status: 'error',
                  isClosable: true,
                });
              }
            }}
          />
        );
      // case 3:
      //   return <Welcome />;
    }
  };

  return (
    <Grid height="100vh" gridAutoColumns="1fr" gridAutoFlow="column">
      <Box backgroundColor="brand.600">
        <Flex alignItems="center" justifyContent="space-between" p="24px">
          <span>Logo</span>
          <Flex gap="10px" alignItems="center">
            <Text>Sei già registrato?</Text>
            <Button
              variant="link"
              colorScheme="teal"
              onClick={() => navigate('/signin')}
            >
              Sign In
            </Button>
          </Flex>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt="25%"
        >
          {renderForm(step)}
        </Flex>
      </Box>

      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        <DoctorIcon />
        <Heading as="h2" size="lg" mt="32px" mb="24px">
          Cos'è Easydoctor
        </Heading>
        <VStack spacing="20px" alignItems="flex-start" ml={20} mr={20}>
          {features.map((x, idx) => (
            <FeaturePoint key={idx} text={x} />
          ))}
        </VStack>
      </Flex>
    </Grid>
  );
};

export default SignUp;

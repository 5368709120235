import { useState } from 'react';
import * as yup from 'yup';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TitleSmall from '../../TitleSmall';
import { IModal } from '../../../interfaces/IModal';
import { colors } from '../../../colors';
import './index.scss';

const schema = yup.object({
  name: yup.string().required('Inserisci il tuo nome'),
  surname: yup.string().required('Inserisci il tuo nome'),
  specialization: yup.string().required('Inserisci il tuo specializzazione'),
  email: yup.string().email(`L'indirizzo email inserito non è valido`),
});

const AddDoctorModal = ({ isOpen, loading, onClose, onSave }: IModal) => {
  const [tags, setTags] = useState<string[]>([]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    onSave(data);
  };

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      isCentered={true}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent backgroundColor="brand.600">
        <ModalHeader pt="24px">
          <TitleSmall text="Nuovo dottore" color={colors[200]} />
        </ModalHeader>
        <ModalCloseButton mt="8px" mr="8px" borderRadius="23px" />

        <ModalBody pb={6} mt="16px">
          <form id="doctor-form" onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing="32px" alignItems="unset">
              <Flex justifyContent="space-between" alignItems="flex-end">
                <FormControl mr="16px" isInvalid={!!errors.name}>
                  <FormLabel htmlFor="name" fontSize="14px">
                    Nome*
                  </FormLabel>
                  <Input
                    id="name"
                    placeholder="Nome"
                    {...register('name')}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.surname}>
                  <Input
                    id="surname"
                    placeholder="Cognome"
                    {...register('surname')}
                    variant="solid"
                  />
                  <FormErrorMessage>{errors.surname?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <FormControl isInvalid={!!errors.specialization}>
                <FormLabel htmlFor="specialization" fontSize="14px">
                  Specializzazione
                </FormLabel>
                <Input
                  id="title"
                  placeholder="Specializzazione"
                  {...register('specialization')}
                  variant="solid"
                />
                <FormErrorMessage>
                  {errors.specialization?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.email}>
                <FormLabel htmlFor="email" fontSize="14px">
                  Email
                </FormLabel>
                <Input
                  id="email"
                  type="email" // FIXME: default error
                  placeholder="Email"
                  {...register('email')}
                  variant="solid"
                />
                {/* FIXME */}
                {/* <FormErrorMessage>{errors.email?.message || errors.phone?.message}</FormErrorMessage> */}
              </FormControl>

              {/* <GreenTagInput
                label="Servizi associati"
                values={tags}
                onChange={(values) => setTags(values)}
              /> */}
            </VStack>
          </form>
        </ModalBody>

        <ModalFooter pb="24px" justifyContent="space-between">
          <Button
            colorScheme="gray"
            variant="outline"
            onClick={onClose}
            backgroundColor="white"
            disabled={loading}
          >
            Annulla
          </Button>
          <Button
            type="submit"
            form="doctor-form"
            colorScheme="teal"
            variant="solid"
            backgroundColor="brand.500"
            isLoading={loading}
          >
            Aggiungi
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddDoctorModal;

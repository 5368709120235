import { SVGProps } from 'react';

const CheckCircledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10Zm-2 0a8 8 0 1 1-2.058-5.356L9 11.586 6.207 8.793a1 1 0 0 0-1.414 1.414L7.586 13a2 2 0 0 0 2.828 0l6.688-6.687A7.966 7.966 0 0 1 18 10Z"
      fill="#66E3C3"
    />
  </svg>
);

export default CheckCircledIcon;

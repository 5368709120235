import { SVGProps } from 'react';

const ClinicIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.334 14.5v-.6h-.6v.6h.6Zm2.5 0h.6v-.6h-.6v.6ZM1 18.333H.4v.6H1v-.6ZM11.667 3.667h.6v-.371l-.332-.166-.268.537ZM6.333 1l.269-.537L6.333.33l-.268.134.268.537ZM1 3.667.732 3.13.4 3.296v.37H1Zm18.333 14.666v.6h.6v-.6h-.6Zm0-10h.6v-.6h-.6v.6ZM5 14.333v-.6h-.6v.6H5Zm2.667 0h.6v-.6h-.6v.6ZM14.934 18v-3.5h-1.2V18h1.2Zm-.6-2.9h2.5v-1.2h-2.5v1.2Zm1.9-.6v4h1.2v-4h-1.2ZM1 18.933h10.667v-1.2H1v1.2Zm11.267-.6V3.667h-1.2v14.666h1.2ZM11.935 3.13 6.602.463l-.537 1.074 5.333 2.666.537-1.073ZM6.065.463.732 3.13l.536 1.073 5.334-2.666L6.065.463ZM.4 3.667v14.666h1.2V3.667H.4Zm19.533 14.666v-10h-1.2v10h1.2Zm-.6-10.6h-7.666v1.2h7.666v-1.2ZM5.6 18.333v-4H4.4v4h1.2Zm-.6-3.4h2.667v-1.2H5v1.2Zm2.067-.6v4h1.2v-4h-1.2Zm12.266 3.4h-7.666v1.2h7.666v-1.2ZM5.733 5.667v2h1.2v-2h-1.2Zm0 2v2h1.2v-2h-1.2Zm-1.4.6h2v-1.2h-2v1.2Zm2 0h2v-1.2h-2v1.2Z"
      fill="#768C9C"
    />
  </svg>
);

export default ClinicIcon;

import { useState, useMemo } from 'react';
import { addMinutes } from 'date-fns';
import { Tablist, Tab, PlusIcon } from 'evergreen-ui';
import { Box, Button, Flex, Spinner, useToast } from '@chakra-ui/react';
import TitleSmall from '../../../../components/TitleSmall';
import VideoWhiteIcon from '../../../../components/Icons/VideoWhiteIcon';
import { colors } from '../../../../colors';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { IAppointment } from '../../../../interfaces/IAppointment';
import format from 'date-fns/format';
import AddAppointmentModal from '../../../../components/Modals/AddAppointmentModal';
import EditAppointmentModal from '../../../../components/Modals/EditAppointmentModal';
import VideoBlackIcon from '../../../../components/Icons/VideoBlackIcon';
import UsersBlackIcon from '../../../../components/Icons/UsersBlackIcon';
import { GET_APPOINTMENTS } from '../../../../graphql/queries/appointments.query';
import { UPDATE_APPOINTMENT } from '../../../../graphql/mutations/update-appointment.mutation';

const serviceList: any = [
  {
    id: 1,
    hospital_id: 1,
    name: 'Visita introduttiva',
    price: '20.00',
    duration: 30,
  },
  {
    id: 2,
    hospital_id: 1,
    name: 'Visita di follow-up',
    price: '10.00',
    duration: 20,
  },
];

const tabs = [
  {
    title: 'In programma',
  },
  {
    title: 'Passate',
  },
  {
    title: 'Cancellate',
  },
];

interface ICardProps {
  title: string;
  duration: number;
  date: string;
  meetingId?: string;
  isOnline?: boolean;
}

const Card = (props: ICardProps) => {
  const color = props.isOnline ? colors[100] : colors[1200];

  return (
    <Flex gap="20px">
      <Box
        w="80px"
        h="80px"
        bg={color}
        borderRadius="8px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {props.isOnline ? <VideoBlackIcon /> : <UsersBlackIcon />}
      </Box>
      <Flex flexDir="column" justifyContent="space-between">
        <Flex alignItems="center" gap="8px" h="32px">
          <Box fontSize="15px" fontWeight="semibold">
            {props.title}
          </Box>
          {props.isOnline && (
            <Box
              h="32px"
              bg="#CABDFF"
              padding="4px 8px"
              borderRadius="6px"
              fontWeight="semibold"
              display="flex"
              alignItems="center"
            >
              Online
            </Box>
          )}
        </Flex>
        <Box fontSize="13px" color="brand.800">
          {props.duration} minuti
        </Box>
        <Box
          h="32px"
          fontSize="14px"
          color="brand.800"
          fontWeight="semibold"
          display="flex"
          alignItems="center"
        >
          {format(new Date(props.date), 'MMM dd, yyyy')} at{' '}
          {format(new Date(props.date), 'HH:mm')}
        </Box>
      </Flex>
    </Flex>
  );
};

interface IRowProps {
  title: string;
  duration: number;
  date: string;
  meetingId?: string;
  isOnline?: boolean;
  event: IAppointment;
  editEvent: (event: IAppointment) => void;
  selectedIndex: number;
}

const Row = (props: IRowProps) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      pt="12px"
      pb="12px"
    >
      <Card
        date={props.date}
        title={props.title}
        duration={props.duration}
        isOnline={props.isOnline}
      />
      <Box>
        <Button
          colorScheme="teal"
          variant="solid"
          leftIcon={<VideoWhiteIcon />}
          height="48px"
          bg="brand.500"
          mr="16px"
        >
          Partecipa
        </Button>
        {props.selectedIndex === 0 ? (
          <Button
            onClick={() => props.editEvent(props.event)}
            variant="outline"
            height="48px"
          >
            Modifica
          </Button>
        ) : null}
      </Box>
    </Flex>
  );
};

const Appointments = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isAddAppointment, setIsAddAppointment] = useState(false);
  const [isEditAppointment, setIsEditAppointment] = useState<{
    isOpen: boolean;
    event: any;
  }>({
    isOpen: false,
    event: null,
  });

  const { data, loading } = useQuery(GET_APPOINTMENTS);
  const [updateAppointment, { loading: updateAppointmentLoading }] =
    useMutation(UPDATE_APPOINTMENT, {
      refetchQueries: [{ query: GET_APPOINTMENTS }],
    });

  // TODO refactor this
  const appointments = useMemo(() => {
    if (selectedIndex === 0) {
      return data.appointments
        .filter((appointment: IAppointment) => {
          return (
            new Date(appointment.startDateTime).getTime() > new Date().getTime()
          );
        })
        .sort((a: any, b: any) =>
          new Date(a.start).getTime() < new Date(b.start).getTime() ? 1 : -1
        );
    }

    if (selectedIndex === 1) {
      return data.appointments
        .filter((appointment: IAppointment) => {
          return (
            new Date(appointment.startDateTime).getTime() < new Date().getTime()
          );
        })
        .sort((a: any, b: any) =>
          new Date(a.start).getTime() < new Date(b.start).getTime() ? 1 : -1
        );
    }

    if (selectedIndex === 2) {
      return [];
    }
  }, [data, selectedIndex]);

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="150px">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  const handleSave = (data: any) => {
    console.log(data);
  };

  const handleUpdate = async (event: any) => {
    try {
      const time = event.startTime.padStart(5, 0);
      const date = format(event.date, 'yyyy-MM-dd');

      const startDateTime = new Date(`${date} ${time}`).toISOString();
      const endDateTime = addMinutes(
        new Date(`${date} ${time}`),
        event.service.duration
      ).toISOString();

      await updateAppointment({
        variables: {
          id: event.id,
          input: {
            startDateTime,
            endDateTime,
            note: event.note,
            serviceId: event.service.id,
            online: Boolean(event.isOnline),
          },
        },
      });

      setIsEditAppointment({ event: null, isOpen: false });
    } catch (error: any) {
      toast({
        title: `Errore durante l'aggiornamento dell'appuntamento.`,
        description: error.message,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const editEvent = (event: any) => {
    setIsEditAppointment({
      isOpen: true,
      event,
    });
  };

  return (
    <Box p="24px" borderRadius="8px" bg="#FCFCFC">
      <AddAppointmentModal
        isOpen={isAddAppointment}
        onClose={() => setIsAddAppointment(false)}
        onSave={handleSave}
        events={[]}
      />

      {isEditAppointment.event && (
        <EditAppointmentModal
          isOpen={isEditAppointment.isOpen}
          loading={updateAppointmentLoading}
          onClose={() => {
            setIsEditAppointment({
              isOpen: false,
              event: null,
            });
          }}
          onSave={handleUpdate}
          event={isEditAppointment.event}
          events={appointments}
        />
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TitleSmall
          text="Appuntamenti"
          color={colors[1100]}
          style={{ marginRight: 24 }}
        />
        <Box>
          <Button
            variant="outline"
            onClick={() => navigate('/calendar')}
            height="46px"
            mr="10px"
          >
            Vai al Calendario
          </Button>
          <Button
            colorScheme="teal"
            variant="solid"
            onClick={() => setIsAddAppointment(true)}
            leftIcon={<PlusIcon />}
            height="48px"
            backgroundColor="brand.500"
          >
            Aggiungi appuntamento
          </Button>
        </Box>
      </Box>
      <Box mt="40px" mb="32px">
        <Tablist mb="16px" flexBasis={240} mr="24px" h="100%">
          {tabs.map(({ title }, index) => (
            <Tab
              className="nav-tab"
              key={title}
              id={title}
              onSelect={() => setSelectedIndex(index)}
              isSelected={index === selectedIndex}
              aria-controls={`Boxl-${title}`}
              height={40}
              borderRadius={8}
              backgroundColor={
                index === selectedIndex
                  ? '#EFEFEF !important'
                  : '#FCFCFC !important'
              }
              color={
                index === selectedIndex
                  ? '#1A1D1F !important'
                  : '#6F767E !important'
              }
              boxShadow="none !important"
            >
              {title}
            </Tab>
          ))}
        </Tablist>
      </Box>

      {appointments.map((x: IAppointment) => (
        <Row
          key={x.id}
          date={x.startDateTime}
          title={`${serviceList.find((s: any) => s.id == x.serviceId).name}`}
          duration={45}
          isOnline={x.online}
          meetingId={x.meetingId}
          event={x}
          editEvent={editEvent}
          selectedIndex={selectedIndex}
        />
      ))}
    </Box>
  );
};

export default Appointments;

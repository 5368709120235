import { forwardRef, useState, useEffect } from 'react';
import { format } from 'date-fns';
import { CrossIcon, Dialog, Pane } from 'evergreen-ui';
import { IconButton, Box, Button } from '@chakra-ui/react';
import TitleSmall from '../../TitleSmall';
import VideoWhiteIcon from '../../Icons/VideoWhiteIcon';
import ModalInfoRow from '../../../components/ModalInfoRow';
import BucketIcon from '../../../components/Icons/BucketIcon';
import ToggleInput from '../../../components/Inputs/ToggleInput';
import { IEventResponse } from '../../../interfaces/IEventResponse';
import GreenCalendarIcon from '../../../components/Icons/GreenCalendarIcon';
import EditGrayIcon from '../../../components/Icons/EditGrayIcon';

const serviceList: any = [
  {
    id: 1,
    hospital_id: 1,
    name: 'Visita introduttiva',
    price: '20.00',
    duration: 30,
  },
  {
    id: 2,
    hospital_id: 1,
    name: 'Visita di follow-up',
    price: '10.00',
    duration: 20,
  },
];

const ViewAppointmentModal = ({
  event,
  onClose,
  isOpen,
  isJoining,
  editEvent,
  onJoin,
}: {
  event: (IEventResponse & { id: string }) | null;
  isOpen: boolean;
  isJoining: boolean;
  onClose: () => void;
  editEvent: (event: (IEventResponse & { id: string }) | null) => void;
  onJoin: (id: string) => void;
}) => {
  const [serviceType, setServiceType] = useState<string>('');

  useEffect(() => {
    if (event?.serviceId) {
      const service = serviceList.find(
        (service: any) => service.id == event.serviceId
      );
      setServiceType(`${service.name} - ${service.duration} minuti`);
    }
  }, [event]);

  const ModalHeader = () => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TitleSmall text="Appuntamento in programma" color="#FFBC99" />
        <IconButton
          border="none"
          aria-label="close"
          icon={<CrossIcon style={{ height: '20px', width: '20px' }} />}
          onClick={onClose}
          background="#EFEFEF"
          variant="circle"
        />
      </Box>
    );
  };

  return (
    <Dialog
      isShown={isOpen}
      hasHeader={false}
      hasFooter={false}
      header={ModalHeader}
      containerProps={{ background: '#F2F5FA' }}
      width={388}
      onCloseComplete={onClose}
    >
      <Pane>
        <ModalInfoRow label="INVITATI" placeholder="" element={event?.title} />
        <ModalInfoRow
          label="TIPO DI APPUNTAMENTO"
          placeholder=""
          element={serviceType}
          marginTop={32}
        />
        <ToggleInput
          marginTop={32}
          title="Appuntamento online"
          checked={!!event?.online}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={(checked) => {}}
        />
        <ModalInfoRow
          label="DATA"
          placeholder=""
          element={
            event?.start
              ? format(new Date(event.start), 'EEEE d MMMM yyyy')
              : null
          }
          marginTop={32}
          iconAfter={<GreenCalendarIcon />}
        />
        <ModalInfoRow
          label="ORA"
          placeholder=""
          element={event?.start ? format(new Date(event.start), 'HH:mm') : null}
          marginTop={32}
          iconAfter={<GreenCalendarIcon />}
        />
        <ModalInfoRow
          label="NOTE"
          placeholder=""
          element={event?.note}
          marginTop={32}
        />
        <Pane
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop={16}
        >
          <Box>
            <IconButton
              variant="ghost"
              background="#FFFFFF"
              borderRadius="12px"
              colorScheme="gray"
              aria-label="edit"
              icon={<EditGrayIcon />}
              mr="16px"
              onClick={() => editEvent(event)}
            />
            <IconButton
              variant="ghost"
              background="#FFFFFF"
              borderRadius="12px"
              colorScheme="gray"
              aria-label="bucket"
              icon={<BucketIcon />}
            />
          </Box>
          <Button
            colorScheme="teal"
            variant="solid"
            leftIcon={<VideoWhiteIcon />}
            backgroundColor="brand.500"
            onClick={() => onJoin(event?.id || '')}
            disabled={!event?.online || isJoining}
            isLoading={isJoining}
          >
            Partecipa
          </Button>
        </Pane>
      </Pane>
    </Dialog>
  );
};

export default forwardRef(ViewAppointmentModal);

import React from 'react';
import { useApolloClient, useQuery } from '@apollo/client/react';
import { useNavigate } from 'react-router-dom';
import { Flex, Spinner } from '@chakra-ui/react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { GET_USER } from '../../graphql/queries/user.query';
import { useAuthContext } from '../../providers/AuthProvider/context';

export const BaseLayout = ({ children }: { children: React.ElementType }) => {
  const navigate = useNavigate();

  const client = useApolloClient();
  const { signOut, inProgress } = useAuthContext();
  const { data, loading } = useQuery(GET_USER);

  if (loading || !data) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        height="100vh"
        flexDirection="column"
      >
        <Spinner
          size="xl"
          color="brand.500"
          thickness="4px"
          speed="0.65s"
          marginTop="10px"
        />
      </Flex>
    );
  }

  const handleLogout = async () => {
    await client.clearStore();
    await signOut();
    navigate('/signin');
  };

  const { me } = data;
  const displayName = `${me?.name} ${me?.surname}`;

  return (
    <div className="app-container">
      {me?.role ? <Sidebar role={me?.role} /> : null}
      <Header
        logoutInProgress={inProgress}
        displayName={displayName}
        handleLogout={handleLogout}
        handleAvatarClick={() => navigate('/profile')}
      />
      <main className="main-content">{children}</main>
    </div>
  );
};

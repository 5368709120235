import { SVGProps } from 'react';

const VideoBlackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 15.429V16a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v.571l1.837-1.312c1.324-.946 3.163 0 3.163 1.627v6.228c0 1.626-1.839 2.573-3.163 1.627L17.5 15.43ZM5.5 7h9a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1Zm12 5.97 3 2.144V8.886l-3 2.143v1.942Z"
      fill="#1A1D1F"
    />
  </svg>
);

export default VideoBlackIcon;

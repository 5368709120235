import {
  Flex,
  IconButton, Text, Tooltip, useClipboard
} from '@chakra-ui/react';
import CopyIcon from '../Icons/CopyIcon';
import PhoneIcon from '../Icons/PhoneIcon';

export const MobileBadge = ({ mobile }: { mobile: string; }) => {
  const clipboard = useClipboard(mobile);

  return (
    <Tooltip
      backgroundColor="brand.500"
      label="Copiato negli appunti"
      fontSize="md"
      isOpen={clipboard.hasCopied}
    >
      <Flex
        alignItems="center"
        justifyContent="space-around"
        borderRadius="32px"
        p="5px"
        backgroundColor="gray.100"
        fontSize="14px"
        w="190px"
      >
        <PhoneIcon />
        <Text fontFamily='Nunito' color='brand.900'>{mobile}</Text>
        <IconButton
          variant="ghost"
          aria-label="Copy phone"
          isRound={true}
          onClick={clipboard.onCopy}
          size="sm"
          icon={<CopyIcon />} />
      </Flex>
    </Tooltip>
  );
};

import {
  ControlBar,
  AudioInputControl,
  VideoInputControl,
  ContentShareControl,
} from 'amazon-chime-sdk-component-library-react';
import EndMeetingControl from '../EndMeetingControl';
import './index.scss';

const MeetingControls = () => {

  return (
    <div className="controls">
      <ControlBar
        className="controls-menu"
        layout="undocked-horizontal"
        showLabels={false}
      >
        <AudioInputControl />
        <VideoInputControl />
        <ContentShareControl />
        {/* <AudioOutputControl /> */}
        <EndMeetingControl />
      </ControlBar>
    </div>
  );
};

export default MeetingControls;

import { gql } from "@apollo/client";

export const GET_CLINICS = gql`
query Query {
    clinics {
      id
      doctorsQuantity
      address
      clinicManager {
        id
        email
        name
        surname
        role
        pec
        profilePhoto
        invitedAt
        registeredAt
      }
    }
  }
`;
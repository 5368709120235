import { SVGProps } from 'react';

const UsersBlackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 20a1 1 0 1 0 2 0h-2Zm12.727 0a1 1 0 1 0 2 0h-2Zm-1.818-5.294a1 1 0 1 0 2 0h-2Zm9.091.441a1 1 0 0 0 2 0h-2ZM3.5 20v-.882h-2V20h2Zm0-.882c0-2.831 2.373-5.177 5.364-5.177v-2c-4.039 0-7.364 3.185-7.364 7.177h2Zm5.364-5.177c2.99 0 5.363 2.346 5.363 5.177h2c0-3.992-3.325-7.177-7.363-7.177v2Zm5.363 5.177V20h2v-.882h-2Zm.182-4.412c0-1.856 1.56-3.412 3.545-3.412v-2c-3.034 0-5.545 2.395-5.545 5.412h2Zm3.545-3.412c1.987 0 3.546 1.556 3.546 3.412h2c0-3.017-2.511-5.412-5.546-5.412v2Zm3.546 3.412v.441h2v-.441h-2ZM8.864 13.94c2.532 0 4.636-2 4.636-4.53h-2c0 1.37-1.152 2.53-2.636 2.53v2Zm4.636-4.53c0-2.529-2.104-4.529-4.636-4.529v2c1.484 0 2.636 1.16 2.636 2.53h2ZM8.864 4.883c-2.533 0-4.637 2-4.637 4.53h2c0-1.37 1.152-2.53 2.637-2.53v-2Zm-4.637 4.53c0 2.53 2.104 4.53 4.637 4.53v-2c-1.485 0-2.637-1.161-2.637-2.53h-2Zm13.727 1.882c2.03 0 3.728-1.605 3.728-3.647h-2c0 .882-.745 1.647-1.727 1.647v2Zm3.728-3.647C21.682 5.605 19.985 4 17.955 4v2c.982 0 1.727.765 1.727 1.647h2ZM17.955 4c-2.03 0-3.728 1.605-3.728 3.647h2c0-.882.745-1.647 1.727-1.647V4Zm-3.728 3.647c0 2.042 1.697 3.647 3.727 3.647v-2c-.982 0-1.727-.765-1.727-1.647h-2Z"
      fill="#000"
    />
  </svg>
);

export default UsersBlackIcon;

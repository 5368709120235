import { gql } from "@apollo/client";

export const CREATE_CLINIC = gql`
mutation Mutation($input: ClinicInput!) {
  createClinic(input: $input) {
    doctorsQuantity
    address
    clinicManager {
      email
      name
      surname
      pec
    }
  }
}
`;

import { IconButton, CrossIcon, Menu } from 'evergreen-ui';
import { Box } from '@chakra-ui/react';
import Title from '../../../Title';
import { IClinicServices } from '../../../../interfaces/IClinicServices';

const TypeInvitationModal = ({
  values,
  isOpen,
  onClose,
  onSelect,
}: {
  values: IClinicServices[];
  isOpen: boolean;
  onClose: () => void;
  onSelect: (service: IClinicServices) => void;
}) => {
  return isOpen ? (
    <Box
      w={340}
      bg="#F1F4F9"
      pos="absolute"
      left={396}
      borderRadius="16px"
      p="24px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="24px"
      >
        <Title
          style={{ fontSize: '20px', marginBottom: '0px' }}
          text="Tipo di appuntamento"
        />
        <IconButton
          border="none"
          aria-label="close"
          icon={<CrossIcon style={{ height: '20px', width: '20px' }} />}
          onClick={onClose}
          background="#EFEFEF"
          borderRadius="50%"
        />
      </Box>
      <Box className="submodal-body">
        <Menu>
          <Menu.Group>
            {values.map((value) => (
              <Menu.Item
                key={value.id}
                onSelect={() => onSelect(value)}
                borderRadius={4}
                height={40}
              >
                {`${value.name} - ${value.duration} minuti`}
              </Menu.Item>
            ))}
          </Menu.Group>
        </Menu>
      </Box>
    </Box>
  ) : null;
};

export default TypeInvitationModal;

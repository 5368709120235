import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tablist, Tab, Pane } from 'evergreen-ui';
import PlusIcon from '../Icons/PlusIcon';
import tabs from './tabs';
import './index.css';

type Role =
  | 'patient'
  | 'doctor'
  | 'clinic_admin'
  | 'agency_admin'
  | 'super_admin';

type SidebarProps = {
  role: Role;
};

const Sidebar = ({ role }: SidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState<null | number>(0);

  useEffect(() => {
    const route = location.pathname;
    const tab = tabs[role].find((x) => route.includes(x.route));
    if (tab) {
      setSelectedIndex(tab.key);
    }
  }, [location.pathname, setSelectedIndex]);

  return (
    <aside className="sidebar">
      <Tablist flexBasis={240}>
        {role
          ? tabs[role].map(({ key, title, route, Icon }) => (
              <Tab
                key={key}
                id={title}
                direction="vertical"
                onSelect={() => {
                  setSelectedIndex(key);
                  navigate(route);
                }}
                isSelected={key === selectedIndex}
                disabled={key === selectedIndex}
                height={48}
                className="tab"
                aria-controls={`panel-${title}`}
              >
                <Pane
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Pane
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <div style={{ marginRight: 10 }}>
                      <Icon />
                    </div>
                    <div>{title}</div>
                  </Pane>

                  <PlusIcon />
                </Pane>
              </Tab>
            ))
          : null}
      </Tablist>
    </aside>
  );
};

export default Sidebar;

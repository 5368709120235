import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  fonts: {
    body: 'Nunito',
    heading: 'Nunito',
  },
  // styles: {
  //   global: {
  //     body: {
  //       fontFamily: 'Nunito'
  //     }
  //   }
  // },
  colors: {
    brand: {
      100: '#CABDFF',
      200: '#B5E4CA',
      300: '#FCFCFC',
      400: '#F4F4F4',
      500: '#096B72',
      600: '#F1F4F9',
      700: '#1A1D1F',
      800: '#6F767E',
      900: '#3F3F3F',
      1000: '#FFBC99',
      1100: 'rgba(9, 107, 114, 0.5);',
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'Nunito', // check this
      },
    },
    Button: {
      variants: {
        solid: {
          borderRadius: '12px',
          // height: '48px',
        },
        outline: {
          borderRadius: '12px',
          // height: '48px',
        },
        link: {
          fontFamily: 'Nunito',
        },
        circle: {
          borderRadius: '50%',
          height: '36px',
          width: '36px',
          minWidth: '36px',
        },
      },
    },
    Input: {
      variants: {
        solid: {
          field: {
            height: '48px',
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        overlay: {
          bg: 'brand.1100',
        },
      },
    },
  },
});

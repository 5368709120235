import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Tag, Box, Button, Spinner, Flex } from '@chakra-ui/react';
import InfoRow from '../../../../components/InfoRow';
import TitleSmall from '../../../../components/TitleSmall';
import EditIcon from '../../../../components/Icons/EditIcon';
import { IPatient } from '../../../../interfaces/IPatient';
import { GET_SANITARY_DATA } from '../../../../graphql/queries/sanitaryData.query';

const SanitaryData = ({ patient }: { patient: IPatient }) => {
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_SANITARY_DATA, { variables: {
    patientId: patient.id
  }});

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="150px">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box p="24px" bg="#FCFCFC" borderRadius="8px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TitleSmall
          text="Dati sanitari"
          color="#CABDFF"
          style={{ marginRight: 24 }}
        />
        <Button
          colorScheme="teal"
          variant="solid"
          onClick={() => navigate('edit')}
          leftIcon={<EditIcon />}
          width="114px"
          height="48px"
          backgroundColor="brand.500"
          disabled={true}
        >
          Modifica
        </Button>
      </Box>
      <Box p="16px" bg="rgba(244, 244, 244, 0.5)" borderRadius={12} mt="24px">
        <InfoRow
          label="Patologie"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.pathologies
              ? data.sanitaryData.pathologies.map((pt: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {pt}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Fattori di rischio"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.riskFactors
              ? data.sanitaryData.riskFactors.map((rf: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {rf}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Capacità motoria"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.motoricalFunction
              ? data.sanitaryData.motoricalFunction.map((mc: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {mc}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Malformazioni rilevanti"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.relatedIllnesses
              ? data.sanitaryData.relatedIllnesses.map((mc: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {mc}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Ausili"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.aids
              ? data.sanitaryData.aids.map((el: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {el}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Protesi"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.prosthesis
              ? data.sanitaryData.prosthesis.map((prt: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {prt}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Organi mancanti"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.missingOrgans
              ? data.sanitaryData.missingOrgans.map((mo: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {mo}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Organi trapiantati"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.transplantedOrgans
              ? data.sanitaryData.transplantedOrgans.map((tr: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {tr}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Organi interessati dalla dichiarazione di donazione"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.organs
              ? data.sanitaryData.organs.map((od: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {od}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Farmaci causa di reazioni avverse"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.medications
              ? data.sanitaryData.medications.map((ar: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {ar}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Allergie cutanee, respiratorie o sistemiche"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.allergies
              ? data.sanitaryData.allergies.map((all: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {all}
                  </Tag>
                ))
              : null
          }
        />
        {/* <InfoRow
          label="Allergie a veleno di imenotteri"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.allergy_hymenoptera_poison
              ? data.sanitaryData.allergy_hymenoptera_poison.map(
                  (ahp: string, i: number) => (
                    <Tag key={i} className="tag-gray" color="#768C9C">
                      {ahp}
                    </Tag>
                  )
                )
              : null
          }
        /> */}
        <InfoRow
          label="Alimenti causa di reazioni avverse"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.foodReactions
              ? data.sanitaryData.foodReactions.map((fr: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {fr}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Vaccinazioni"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.vaccinations
              ? data.sanitaryData.vaccinations.map((vac: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {vac}
                  </Tag>
                ))
              : null
          }
        />
        <InfoRow
          label="Gravidanze"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.pregnancies
              ? data.sanitaryData.pregnancies.map((pr: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {pr}
                  </Tag>
                ))
              : null
          }
        />
        {/* <InfoRow
          label="Parti"
          placeholder="Non definito"
          element={
            data?.sanitaryData?.set_off
              ? data.sanitaryData.set_off.map((so: string, i: number) => (
                  <Tag key={i} className="tag-gray" color="#768C9C">
                    {so}
                  </Tag>
                ))
              : null
          }
        /> */}
      </Box>
    </Box>
  );
};

export default SanitaryData;

import { IconButton, Tooltip, useClipboard } from '@chakra-ui/react';
import MailIcon from '../Icons/MailIcon';

export const EmailButton = ({ email }: { email: string; }) => {
  const clipboard = useClipboard(email);

  return (
    <Tooltip
      backgroundColor="brand.500" // TODO: move to theme
      label="Copiato negli appunti"
      fontSize="md"
      isOpen={clipboard.hasCopied}
    >
      <IconButton
        aria-label="Copy email"
        icon={<MailIcon />}
        isRound={true}
        onClick={clipboard.onCopy} />
    </Tooltip>
  );
};

import { ICalendarEvent } from '../interfaces/ICalendarEvent';

const zeroPad = (num: number) => String(num).padStart(2, '0');

export const formatTime = (time: string): string => {
  const [hh, mm] = time.split(':');
  let formatted = '';

  if (hh.length === 1) {
    formatted = '0';
  }

  formatted = `${formatted}${hh}:${mm}:00`;

  return formatted;
};

export const getAvailableTime = (
  date: Date,
  events: ICalendarEvent[],
  range: number
) => {
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const selectedDate = date.setHours(0, 0, 0, 0);
  const startTime =
    currentDate === selectedDate
      ? new Date(date).setHours(
          new Date().getHours(),
          Math.ceil(new Date().getMinutes() / 10) * 10,
          0,
          0
        )
      : new Date(date).setHours(8, 0, 0, 0);
  const endTime = new Date(date).setHours(21, 0, 0, 0);
  const minInterval = 900000;
  const millisecondsRange = range * 60000;
  const dayEvents = events.filter(
    (event) =>
      Date.parse(event.startDateTime) >= startTime &&
      Date.parse(event.endDateTime) < endTime
  );

  const timeList = [];
  let start = startTime;

  while (start <= endTime - millisecondsRange) {
    if (
      !dayEvents.some(
        (dayEvent) =>
          start + millisecondsRange > Date.parse(dayEvent.startDateTime) &&
          start < Date.parse(dayEvent.endDateTime)
      )
    ) {
      const startDate = new Date(start);
      timeList.push(
        `${startDate.getHours()}:${zeroPad(startDate.getMinutes())}`
      );
    }

    start += minInterval;
  }
  return timeList;
};

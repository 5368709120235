import { SVGProps } from 'react';

const BlackCalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 4V2m0 2v2m0-2H11m-7.5 6v9a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-9h-18ZM3.5 10V6a2 2 0 0 1 2-2h2M7.5 2v4M21.5 10V6a2 2 0 0 0-2-2H19"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BlackCalendarIcon;
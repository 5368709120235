export const colors = {
  100: '#CABDFF',
  200: '#B5E4CA',
  300: '#FCFCFC',
  400: '#F4F4F4',
  500: '#096B72',
  600: '#F1F4F9',
  700: '#1A1D1F',
  800: '#6F767E',
  900: '#3F3F3F',
  1000: '#FFBC99',
  1100: '#B1E5FC',
  1200: '#FFEED1'
};

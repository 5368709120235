import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { PlusIcon, SearchIcon } from 'evergreen-ui';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Tag,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  FilterFn,
} from '@tanstack/react-table';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import { EmailButton } from '../../../components/EmailButton';
import { MobileBadge } from '../../../components/MobileBadge';
import TitleSmall from '../../../components/TitleSmall';
import { IPatient } from '../../../interfaces/IPatient';
import AddPatientModal from '../../../components/Modals/AddPatientModal';
import AddAppointmentModal from '../../../components/Modals/AddAppointmentModal';
import { colors } from '../../../colors';
import PatientsTable from './PatientsTable';
import { GET_PATIENTS } from '../../../graphql/queries/patients.query';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_PATIENT } from '../../../graphql/mutations/create-patient.mutation';
import { CREATE_APPOINTMENT } from '../../../graphql/mutations/create-appointment.mutation';
import { addMinutes } from 'date-fns';
import { GET_USER } from '../../../graphql/queries/user.query';
import { GET_APPOINTMENTS } from '../../../graphql/queries/appointments.query';

const globalFilterFn: FilterFn<IPatient> = (row, columnId, filterValue) => {
  if (columnId === 'tag') {
    const tags = row.original.tags ?? [];
    const tag = tags.find((x: string) =>
      x.toLowerCase().includes(filterValue.toLowerCase())
    );
    return tag ? true : false;
  }

  const data: string = row.getValue(columnId);
  return data.toLowerCase().includes(filterValue.toLowerCase());
};

// enum PatientStatus {
//   REGISTERED = 'registered',
//   INVITED = 'invited',
// }

const Patients = () => {
  const patientModalRef = useRef<{ resetForm: () => void }>();

  const navigate = useNavigate();
  const toast = useToast();

  // const [status, setStatus] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');
  const [isAddPatient, setIsAddPatient] = useState(false);
  const [isAddAppointment, setIsAddAppointment] = useState<{
    openModal: boolean;
    patientId: number | null;
  }>({
    openModal: false,
    patientId: null,
  });

  const userQuery = useQuery(GET_USER);
  const patientsQuery = useQuery(GET_PATIENTS);
  const { data: appointments } = useQuery(GET_APPOINTMENTS);

  const [createPatient, { loading }] = useMutation(CREATE_PATIENT, {
    refetchQueries: [{ query: GET_PATIENTS }],
  });
  const [createAppointment] = useMutation(CREATE_APPOINTMENT, {
    refetchQueries: [{ query: GET_APPOINTMENTS }],
  });

  // const handleStatusChanged = (value: PatientStatus) => {
  //   setStatus(value);
  // };

  const handleSaveAppointment = async (event: any) => {
    try {
      const time = event.startTime.padStart(5, 0);
      const date = format(event.date, 'yyyy-MM-dd');

      const startDateTime = new Date(`${date} ${time}`).toISOString();
      const endDateTime = addMinutes(
        new Date(`${date} ${time}`),
        event.service.duration
      ).toISOString();

      const { id, email, name, surname } = userQuery.data.me;

      await createAppointment({
        variables: {
          input: {
            serviceId: event.service.id,
            startDateTime,
            endDateTime,
            online: event.isOnline,
            note: event.note,
            patientId: parseInt(event.patientId),
            attendees: [
              {
                user: {
                  id: parseInt(id),
                  email,
                  displayName: `${name} ${surname}`,
                },
              },
              {
                user: {
                  id: parseInt(event.patientId),
                  email: event.email,
                  displayName: `${event.name} ${event.surname}`,
                },
              },
            ],
          },
        },
      });
      setIsAddAppointment({ openModal: false, patientId: null });
    } catch (error: any) {
      toast({
        title: `Errore durante la creazione dell'appuntamento.`,
        description: error.message,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleSavePatient = async (data: any) => {
    try {
      await createPatient({
        variables: {
          input: {
            name: data.name,
            surname: data.surname,
            email: data.email,
            fiscalCode: data.fiscalCode,
            note: data.note,
            tags: data.tags,
          },
        },
      });
      setIsAddPatient(false);
      patientModalRef.current?.resetForm();
    } catch (error: any) {
      toast({
        title: 'Errore durante la creazione del paziente.',
        description: error.message,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const columnsDef: ColumnDef<IPatient>[] = [
    {
      id: 'name',
      header: 'Nome',
      accessorFn: (row) => `${row.name} ${row.surname}`,
      cell: ({ row, getValue }) => {
        const id = row.original.id;
        const name = getValue() as string;

        const handleClick = () => {
          navigate(`/patients/${id}`);
        };

        return (
          <Flex alignItems="center" gap="10px">
            <Avatar name={name} />
            <Button
              variant="link"
              colorScheme="teal"
              fontSize="15px"
              fontWeight="bold"
              color="brand.700"
              onClick={handleClick}
              whiteSpace="break-spaces"
              textAlign="start"
            >
              {name}
            </Button>
          </Flex>
        );
      },
    },
    {
      header: 'Contatto',
      accessorFn: (row) => ({ email: row.email, mobile: row.phone }),
      id: 'contact',
      cell: ({ getValue }) => {
        const values = getValue() as {
          email: string | undefined;
          mobile: string | undefined;
        };

        return (
          <Flex gap="10px" alignItems="center">
            {values.mobile && <MobileBadge mobile={values.mobile} />}
            {values.email && <EmailButton email={values.email} />}
          </Flex>
        );
      },
    },
    {
      header: 'Prenota una visita',
      enableGlobalFilter: false,
      accessorKey: 'id',
      cell: ({ getValue }) => (
        <Button
          marginRight={10}
          borderRadius={8}
          onClick={() => {
            setIsAddAppointment({
              openModal: true,
              patientId: getValue() as number,
            });
          }}
        >
          Aggiungi appuntamento
        </Button>
      ),
    },
    {
      accessorKey: 'id',
      id: 'tag',
      header: 'Tag',
      cell: ({ row }) => {
        const tags = row.original.tags ?? [];
        return tags.map((x, idx) => (
          <Tag
            key={idx}
            variant="solid"
            colorScheme="teal"
            marginRight="4px"
            size="sm"
          >
            {x}
          </Tag>
        ));
      },
    },
  ];

  const data = useMemo(
    () => patientsQuery.data?.patients || [],
    [patientsQuery.data]
  );
  const columns = useMemo(() => columnsDef, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    globalFilterFn: globalFilterFn,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (patientsQuery.loading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="100%">
        <Spinner size="xl" color="brand.500" thickness="4px" speed="0.65s" />
      </Flex>
    );
  }

  return (
    <Box h="100%" mr="91px" ml="91px">
      <AddPatientModal
        ref={patientModalRef}
        loading={loading}
        isOpen={isAddPatient}
        onClose={() => setIsAddPatient(false)}
        onSave={handleSavePatient}
      />

      <AddAppointmentModal
        isOpen={isAddAppointment.openModal}
        onClose={() =>
          setIsAddAppointment({ openModal: false, patientId: null })
        }
        onSave={handleSaveAppointment}
        events={appointments?.appointments}
        patientId={isAddAppointment.patientId}
      />

      <Text fontSize="40px" fontWeight="semibold" pt="40px" pb="24px">
        Lista pazienti
      </Text>

      <Box backgroundColor="brand.300">
        <Flex
          height="76px"
          paddingInlineStart="24px"
          paddingInlineEnd="24px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <TitleSmall
              text="Pazienti"
              color={colors[100]}
              style={{ marginRight: 24 }}
            />
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray" />}
              />
              <Input
                placeholder="Cerca per nome o altro"
                value={globalFilter ?? ''}
                background="brand.400 !important"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setGlobalFilter(e.target.value)
                }
              />
            </InputGroup>
          </Flex>

          {/* <Box>
            <Button
              colorScheme="gray"
              variant="ghost"
              isActive={status === PatientStatus.REGISTERED}
              onClick={() => handleStatusChanged(PatientStatus.REGISTERED)}
              marginRight={2}
            >
              Registrato
            </Button>
            <Button
              colorScheme="gray"
              variant="ghost"
              isActive={status === PatientStatus.INVITED}
              onClick={() => handleStatusChanged(PatientStatus.INVITED)}
            >
              Invitato
            </Button>
          </Box> */}

          <Button
            colorScheme="teal"
            variant="solid"
            onClick={() => setIsAddPatient(true)}
            leftIcon={<PlusIcon />}
            width="188px"
            height="48px"
            backgroundColor="brand.500"
          >
            Aggiungi paziente
          </Button>
        </Flex>
      </Box>

      <PatientsTable table={table} />
    </Box>
  );
};

export default Patients;

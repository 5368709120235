import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';

export const authLink = setContext(() =>
  Auth.currentSession().then(session => ({
    headers: {
      authorization: session.getAccessToken().getJwtToken()
    },
  }))
);

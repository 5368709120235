import { gql } from '@apollo/client';

export const GET_AVAILABILITY = gql`
  query getAvailability($doctorId: ID!) {
    availability(doctorId: $doctorId) {
      doctorId
      slots {
        start
        end
        dow
      }
    }
  }
`;

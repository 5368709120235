import { forwardRef } from 'react';
import { TextInputField, BoxOwnProps, Pane } from 'evergreen-ui';
import BlackCalendarIcon from '../../Icons/BlackCalendarIcon';

import './index.scss';

interface IProps extends BoxOwnProps {
  id?: string;
  styles?: any;
  label?: string;
  isSecure?: boolean;
  className?: string;
  value: Date | null;
  placeholder?: string;
  iconBackgroundColor?: string;
  onClick?: (() => void) | undefined;
  onChange: (value: Date | null) => void;
}

const CalendarInput = ({
  id,
  label,
  value,
  isSecure,
  placeholder,
  className = '',
  iconBackgroundColor = '#F4F4F4',
  onClick,
  onChange,
  ...props
}: IProps) => {
  return (
    <Pane
      className="calendar-input"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={32}
      width="100%"
      backgroundColor="#FFFFFF !important"
      borderRadius={12}
      overflow="hidden"
      height={48}
    >
      <Pane
        backgroundColor={`${iconBackgroundColor} !important`}
        width={48}
        height={48}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <BlackCalendarIcon />
      </Pane>
      <TextInputField
        width="100%"
        className={`input-box input-box__with-icon ${className}`}
        placeholder={placeholder || ''}
        value={value}
        label=""
        onChange={(e: any) => onChange(e.target.value)}
        id={id}
        onClick={onClick}
        margin={0}
        padding={0}
        {...props}
      />
    </Pane>
  );
};

export default forwardRef(CalendarInput);
